export enum MessageResponsePartType {
  Chunk = 'chunk',
}

// export interface MessageResponsePart<Content = unknown> {
//   run_id: string;
//   type: MessageResponsePartType;
//   name: string;
//   content: Content;
// }

export interface MessageResponsePart {
  content?: string;
  type?: 'error';
  error_code?: 'chat_limit_reached';
}

// export const isMarkdownPart = (part: MessageResponsePart): part is MessageResponsePart<string> => {
//   return part.type === MessageResponsePartType.Chunk && typeof part.content === 'string';
// };
