import { categoriesResolver } from '@examdojo/category';
import { RootUrlParts } from '../app.model';
import { AuthGuard } from '../auth/auth.guard';
import { syllabusesResolver } from '../features/learn-hub/syllabus.resolver';
import { hasRoleGuard } from '../waitlist/waitlist.guard';

export const userOnboardingRoutes = [
  {
    path: RootUrlParts.Onboarding,
    canActivate: [AuthGuard, hasRoleGuard],
    resolve: {
      categories: categoriesResolver,
      syllabuses: syllabusesResolver,
    },
    loadComponent: () => import('./user-onboarding.component').then((m) => m.UserOnboardingComponent),
  },
];
