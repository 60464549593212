@if (state.activePageVersion; as version) {
  <div class="flex items-center justify-between gap-4">
    <div class="flex items-center gap-4"></div>
    <div class="flex items-center gap-4"></div>
  </div>

  <div class="flex gap-4">
    <div class="grow">
      <dojo-tiptap-editor
        [formCtrl]="contentCtrl"
        [editable]="false"
        [scrollableParent]="scrollableContainer()"
        #tiptapEditor
        class="dojo-app-editor"
      />
    </div>

    <div class="sticky top-8 h-full shrink-0 pb-4">
      <div class="font-medium-exam text-black-pure mb-4 px-4 text-sm">On this page</div>
      <dojo-tiptap-table-of-contents
        [editor]="tiptapEditor.editor"
        [tableOfContentData]="tiptapEditor.tableOfContentData()"
        [scrollableParent]="scrollableContainer()"
      />
    </div>
  </div>
} @else {
  @if (state.activePageVersion === null) {
    <y42-empty-state [body]="'examdojo.no_data' | transloco" />
  } @else {
    <dojo-loader />
  }
}
