import { Injectable } from '@angular/core';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import { from } from 'rxjs';
import { TopicsTree } from './model';

@Injectable({
  providedIn: 'root',
})
export class CategoryHttpService {
  constructor(private readonly supabase: ExamdojoSupabaseService) {}

  fetchTopicsTree() {
    return from(
      this.supabase.client
        .schema('categories')
        .from('topics_tree')
        .select()
        .throwOnError()
        .then((response) => response.data![0].jsonb_agg as TopicsTree),
    );
  }
}
