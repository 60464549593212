import { NgModule, PipeTransform, Type } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormatDateRelativeToNowPipe } from './format-date-relative-to-now.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { FormatDurationPipe } from './format-duration.pipe';
import { MaxDateRangeModule } from './max-date-range/max-date-range.module';

const DateTimeFormattingPipes: Array<Type<PipeTransform>> = [FormatDateRelativeToNowPipe, FormatDurationPipe];

@NgModule({
  imports: [FormatDatePipe],
  declarations: [...DateTimeFormattingPipes],
  exports: [...DateTimeFormattingPipes, FormatDatePipe, MatDatepickerModule, MaxDateRangeModule],
})
export class DateTimeModule {}
