import { Injectable } from '@angular/core';
import { EntityState, EntityStore } from '@examdojo/state';
import { PageVersionStoreModel } from './page-version.model';

export type PageVersionState = EntityState<PageVersionStoreModel, object, number>;

@Injectable({ providedIn: 'root' })
export class PageVersionStore extends EntityStore<PageVersionState, PageVersionStoreModel, 'id', number> {
  constructor() {
    super({
      name: 'page-versions',
      idKey: 'id',
    });
  }
}
