<dojo-topic-breadcrumbs class="mb-2 mt-6 block" />

@if (state.topic; as topic) {
  <div class="text-display-xl mb-6 text-neutral-950">
    {{ topic.code }} <dojo-localized-string [content]="topic.name" />
  </div>
}

<div class="custom-mat-tab mat-tab-navbar">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false">
    @for (tab of tabs; track tab) {
      <a mat-tab-link [routerLink]="tab" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
        {{ 'examdojo.learn_hub.topic_tabs.' + tab | transloco }}
      </a>
    }
  </nav>
</div>

<mat-tab-nav-panel #tabPanel>
  <div class="py-8">
    <router-outlet></router-outlet>
  </div>
</mat-tab-nav-panel>
