import { Injectable } from '@angular/core';
import { Query } from '@examdojo/state';
import { isNotNullish } from '@examdojo/util/nullish';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { CategoryState, CategoryStore } from './category.store';
import { HydratedSyllabusTopicsTree } from './model';
import { SyllabusQuery } from './syllabus';
import { TopicLevel1Query } from './topic-level-1';
import { TopicLevel2Query } from './topic-level-2';

@Injectable({ providedIn: 'root' })
export class CategoryQuery extends Query<CategoryState> {
  constructor(
    protected override readonly store: CategoryStore,
    private readonly syllabusQuery: SyllabusQuery,
    private readonly topicLevel1Query: TopicLevel1Query,
    private readonly topicLevel2Query: TopicLevel2Query,
  ) {
    super(store);
  }

  readonly activeSyllabusTopics$: Observable<HydratedSyllabusTopicsTree> = combineLatest([
    this.syllabusQuery.active$,
    this.select('topicsTree'),
  ]).pipe(
    switchMap(([activeSyllabus, allTopics]) => {
      if (!activeSyllabus || !allTopics) {
        return of([]);
      }

      const syllabusTree = allTopics.find(({ id }) => id === activeSyllabus.id);
      if (!syllabusTree) {
        return of([]);
      }

      return combineLatest([this.topicLevel1Query.entities$, this.topicLevel2Query.entities$]).pipe(
        map(([topicsLevel1, topicsLevel2]): HydratedSyllabusTopicsTree => {
          return syllabusTree.topics_level_01
            .map((topicLevel1) => {
              const topicLevel1UIModel = topicsLevel1.find(({ id }) => id === topicLevel1.id);

              if (!topicLevel1UIModel) {
                return null;
              }

              return {
                ...topicLevel1UIModel,
                level: 1,
                code: topicLevel1.code,
                children: topicLevel1.topics_level_02
                  .map((topicLevel2) => {
                    const topicLevel2UIModel = topicsLevel2.find(({ id }) => id === topicLevel2.id);

                    if (!topicLevel2UIModel) {
                      return null;
                    }

                    return {
                      ...topicLevel2UIModel,
                      level: 2,
                      code: topicLevel2.code,
                      latest_pages: topicLevel2.latest_pages ?? [],
                    } as const;
                  })
                  .filter(isNotNullish),
              } as const;
            })
            .filter(isNotNullish);
        }),
      );
    }),
  );
}
