import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { FormatDatePipe } from '@examdojo/core/date-time';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { ButtonComponent } from '@examdojo/ui/button';
import { EMPTY, tap } from 'rxjs';
import { BillingService } from './billing.service';

@Component({
  selector: 'dojo-billing-info',
  standalone: true,
  imports: [ButtonComponent, FormatDatePipe],
  templateUrl: './billing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingInfoComponent {
  constructor(
    private readonly billingService: BillingService,
    private readonly destroyRef: DestroyRef,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  readonly state = connectState({
    userHasProPlan: this.billingService.userHasProPlan$,
    customerPortalLoading: this.billingService.customerPortalLoading$,
    checkoutSessionLoading: this.billingService.checkoutSessionLoading$,
    activeSubscription: this.billingService.activeSubscription$,
  });

  openProPlanCheckout() {
    this.billingService
      .fetchCheckoutSessionUrl()
      .pipe(
        tap((checkoutUrl) => {
          window.location.href = checkoutUrl;
        }),
        this.errorHandlerService.catchError('[BillingComponent]: Failed to generate checkout session', () => EMPTY, {
          // TODO: refactor to support granular error handling
          toast: 'Failed to open checkout session',
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  openCustomerPortal() {
    this.billingService
      .fetchCustomerPortalUrl()
      .pipe(
        tap((portalUrl) => {
          window.location.href = portalUrl;
        }),
        this.errorHandlerService.catchError(
          '[BillingComponent]: Failed to generate customer portal session',
          () => EMPTY,
          {
            // TODO: refactor to support granular error handling
            toast: 'Failed to open customer portal',
          },
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
