import { Injectable } from '@angular/core';
import { DateTimeService } from '@examdojo/core/date-time';
import { QueryEntity } from '@examdojo/state';
import { map, Observable } from 'rxjs';
import { PageType, PageVersionStoreModel, PageVersionUIModel } from './page-version.model';
import { PageVersionState, PageVersionStore } from './page-version.store';

@Injectable({ providedIn: 'root' })
export class PageVersionQuery extends QueryEntity<
  PageVersionState,
  PageVersionStoreModel,
  PageVersionUIModel,
  'id',
  number
> {
  constructor(
    protected override store: PageVersionStore,
    private readonly dateTimeService: DateTimeService,
  ) {
    super(store);
  }

  override readonly toUIModelFn = (entity: PageVersionStoreModel): PageVersionUIModel => {
    return {
      ...entity,
      created_at: entity.created_at ? `${entity.created_at}Z` : null,
      updated_at: entity.updated_at ? `${entity.updated_at}Z` : null,
    };
  };

  getPageVersionsOfType(pageType: PageType): Observable<PageVersionUIModel[]> {
    return this.entities$.pipe(
      map((entities) => {
        if (!pageType) {
          return [];
        }
        return entities.filter((entity) => entity.page_type === pageType);
      }),
    );
  }

  getMainPageVersionForType(pageType: PageType): Observable<PageVersionUIModel> {
    return this.getPageVersionsOfType(pageType).pipe(
      map((pageVersions) =>
        pageVersions.toSorted((a, b) => {
          if (!a.updated_at && !b.updated_at) {
            return 0;
          }
          if (!a.updated_at) {
            return 1;
          }
          if (!b.updated_at) {
            return -1;
          }

          return this.dateTimeService.isBefore(a.updated_at!, b.updated_at!) ? 1 : -1;
        }),
      ),
      map((pageVersions) => pageVersions.find((page) => page.page_status !== 'archived') || pageVersions[0]),
    );
  }

  getPublishedVersionForType(pageType: PageType): Observable<PageVersionUIModel | null> {
    return this.getPageVersionsOfType(pageType).pipe(
      map((pageVersions) => pageVersions.find((page) => page.page_status === 'published') ?? null),
    );
  }
}
