import { HttpErrorResponse } from '@angular/common/http';

export function isBackendError(err: unknown): err is PostgrestError | HttpErrorResponse | TypeError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return isAngularHttpError(err) || isPostgrestError(err) || isConnectionError(err);
}

export function isAngularHttpError(err: unknown): err is HttpErrorResponse {
  return err instanceof HttpErrorResponse;
}

export function isPostgrestError(err: unknown): err is PostgrestError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (err as any)?.name === 'PostgrestError';
}

export function isConnectionError(err: unknown): err is TypeError {
  return err instanceof TypeError && err.message === 'Failed to fetch';
}

export interface PostgrestError {
  message: string;
  details: string | null;
  hint: string | null;
  code: string;
}
