import { Injectable } from '@angular/core';
import { SupabaseBaseHttpService } from '@examdojo/supabase';
import { from } from 'rxjs';
import { TopicLevel1StoreModel } from './topic-level-1.model';

@Injectable({
  providedIn: 'root',
})
export class TopicLevel1HttpService extends SupabaseBaseHttpService<'topics_level_01', 'categories'> {
  readonly schema = 'categories';
  readonly tableName = 'topics_level_01';

  fetchByIds(ids: Array<TopicLevel1StoreModel['id']>) {
    return from(
      this.supabase.client
        .schema(this.schema)
        .from(this.tableName)
        .select()
        .in('id', ids)
        .throwOnError()
        .then((response) => response.data!),
    );
  }
}
