import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { map, Observable, tap } from 'rxjs';
import { CategoryHttpService } from './category-http.service';
import { CategoryStore } from './category-store.service';
import { CategoryHttpModel, CategoryStoreModel } from './category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(
    private readonly store: CategoryStore,
    private readonly categoryHttpService: CategoryHttpService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  fetchAll(): Observable<CategoryStoreModel[]> {
    return this.categoryHttpService.fetchAll().pipe(
      map((data) => data.map((d) => this.mapCategoryHttpModelToStoreModel(d))),
      tap((entities) => {
        this.store.upsertMany(entities);
      }),
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'categories' }),
    );
  }

  fetch(id: CategoryStoreModel['id']): Observable<CategoryStoreModel | null> {
    return this.categoryHttpService.fetch(id).pipe(
      map((data) => {
        if (!data) {
          return null;
        }
        const entity = this.mapCategoryHttpModelToStoreModel(data);
        this.store.upsert(entity.id, entity);
        return entity;
      }),
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'category' }),
    );
  }

  private mapCategoryHttpModelToStoreModel(categoryHttpModel: CategoryHttpModel): CategoryStoreModel {
    return categoryHttpModel as CategoryStoreModel;
  }
}
