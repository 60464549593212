import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, input, Input } from '@angular/core';
import { connectState, InputObservable } from '@examdojo/angular/util';
import { BaseButton } from '@examdojo/core/button';
import { Icon, IconComponent, IconType } from '@examdojo/core/icon';
import { IconButtonSize } from '@examdojo/core/icon-button';
import { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { IonButton, IonSpinner } from '@ionic/angular/standalone';
import { combineLatest, map, Observable } from 'rxjs';

@Component({
  selector: 'dojo-icon-button',
  standalone: true,
  imports: [IonButton, IonSpinner, IconComponent, NgClass],
  templateUrl: './icon-button.component.html',
  host: { class: 'inline-flex' },
  styleUrl: './icon-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BaseButton, useExisting: IconButtonComponent }],
})
export class IconButtonComponent extends BaseButton {
  @Input({ required: true }) icon!: Icon;
  @Input() prefix: IconPrefix = 'fal';
  @Input() iconClass?: string;
  @Input() transparent?: boolean;
  @Input() small?: boolean;

  @Input()
  @InputObservable()
  size?: IconButtonSize;
  size$!: Observable<IconButtonSize>;

  @Input()
  @InputObservable()
  iconSize?: number | string;
  iconSize$!: Observable<number | string>;

  readonly iconType = input<IconType>();

  @HostBinding('attr.disabled')
  get disabledAttr() {
    return this.disabled() ? true : undefined;
  }

  @HostBinding('style.pointer-events')
  get pointerEvents() {
    return this.disabled() ? 'none' : undefined;
  }

  readonly ICON_DEFAULT_SIZE = 16;
  readonly ICON_COMPACT_SIZE = 12;

  readonly state = connectState({
    iconSize: combineLatest([this.size$, this.iconSize$]).pipe(
      map(([size, iconSize]) => {
        if (iconSize) {
          return iconSize;
        } else if (size === 'compact') {
          return this.ICON_COMPACT_SIZE;
        } else {
          return this.ICON_DEFAULT_SIZE;
        }
      }),
    ),
  });
}
