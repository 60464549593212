import { Injectable } from '@angular/core';
import { Query } from '@examdojo/state';
import { filter, map, startWith } from 'rxjs';
import { GradeStatus, QuestionState } from './question.model';
import { QuestionStore } from './question.store';

const POSITIVE_GRADE_ACCURACY_THRESHOLD = 0.5;
const WARNING_GRADE_ACCURACY_THRESHOLD = 0.3;

@Injectable({
  providedIn: 'root',
})
export class QuestionQuery extends Query<QuestionState> {
  constructor(protected override readonly store: QuestionStore) {
    super(store);
  }

  private readonly engagementStatus$ = this.select('engagementStatus');

  readonly userXp$ = this.select('userXp').pipe(filter(Boolean));

  readonly accuracyPct$ = this.userXp$.pipe(
    map(({ actual_marks, max_marks }) => {
      if (!max_marks) {
        return null;
      }
      return (actual_marks ?? 0) / max_marks;
    }),
    startWith(null),
  );

  readonly gradeStatus$ = this.accuracyPct$.pipe(
    map((accuracy) => {
      if (accuracy === null) {
        return null;
      }
      if (accuracy >= POSITIVE_GRADE_ACCURACY_THRESHOLD) {
        return GradeStatus.Positive;
      }
      if (accuracy >= WARNING_GRADE_ACCURACY_THRESHOLD) {
        return GradeStatus.Warning;
      }
      return GradeStatus.Fail;
    }),
  );

  readonly totalXpEarned$ = this.userXp$.pipe(
    map(({ base_xp, pace_xp, accuracy_xp }) => base_xp + pace_xp + accuracy_xp),
    startWith(null),
  );

  readonly encouragementText$ = this.gradeStatus$.pipe(
    map((status) => (status === GradeStatus.Positive ? 'congrats' : 'keep_practicing')),
  );

  readonly buttonText$ = this.engagementStatus$.pipe(
    map((engagementStatus) => {
      return engagementStatus?.status === 'GRADED' ? 'explanation' : 'get_hint';
    }),
  );

  readonly questionFlagged$ = this.select('userVote').pipe(map((vote) => vote?.vote === false));
}
