<div class="group h-full bg-stone-50 p-3" [class.is-compact]="isCompact">
  <div class="flex h-full flex-col justify-between">
    <div class="grow">
      <a
        [routerLink]="'/'"
        class="logo-link mb-4 flex h-[54px] rounded-[0.75rem] px-4 transition-colors hover:bg-neutral-100 group-[.is-compact]:px-2"
      >
        @if (isCompact) {
          <img src="assets/images/logo/logo-compact.svg" alt="Exam dojo" class="w-[26px]" />
        } @else {
          <img src="assets/images/logo/logo-color-horizontal.svg" alt="Exam dojo" class="w-[147px]" />
        }
      </a>

      <div class="flex flex-col gap-2">
        @for (page of featurePages; track page.route) {
          <a
            class="sidebar-link text-secondary text-md flex items-center gap-4 px-4 py-2 font-medium group-[.is-compact]:px-2"
            [routerLink]="page.route"
            routerLinkActive="active"
          >
            <img [src]="page.icon" [alt]="page.label" class="w-[31px]" />
            <span class="sidebar-link-text">{{ page.label | transloco }}</span>
          </a>
        }
      </div>
    </div>

    @if (state.user) {
      <a
        class="sidebar-link text-secondary flex items-center gap-4 rounded-[0.75rem] px-4 py-0.5 font-medium transition-colors hover:bg-neutral-100 group-[.is-compact]:px-2"
        [routerLink]="RootUrlParts.Settings"
        routerLinkActive="active"
      >
        <dojo-profile-avatar [size]="32" class="shrink-0" />
        <span class="sidebar-link-text text-md truncate">{{ state.user.fullName }}</span>
        <dojo-icon-button
          [icon]="'ellipsis'"
          [transparent]="true"
          [iconSize]="20"
          class="ml-auto"
          [class.hidden]="isCompact"
        />
      </a>
    }
  </div>
</div>
