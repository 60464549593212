import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { assertNonNullable } from '@examdojo/util/assert';
import { TableOfContentData } from '@tiptap-pro/extension-table-of-contents';
import { Editor } from '@tiptap/core';
import { TextSelection } from 'prosemirror-state';

@Component({
  selector: 'dojo-tiptap-table-of-contents',
  standalone: true,
  imports: [NgClass, MarkdownViewerComponent],
  templateUrl: './tiptap-table-of-contents.component.html',
  styleUrl: './tiptap-table-of-contents.component.scss',
  host: { class: 'text-sm flex flex-col text-decoration-none' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiptapTableOfContentsComponent {
  readonly tableOfContentData = input.required<TableOfContentData>();
  readonly editor = input.required<Editor>();
  readonly scrollableParent = input<HTMLElement>();

  onItemClick(id: string) {
    const editor = this.editor();
    const scrollableParent = this.scrollableParent();

    if (!scrollableParent) {
      return;
    }

    const element = editor.view.dom.querySelector(`[data-toc-id="${id}"`);
    assertNonNullable(element, 'element');

    const pos = editor.view.posAtDOM(element, 0);

    // set focus
    const tr = editor.view.state.tr;
    tr.setSelection(new TextSelection(tr.doc.resolve(pos)));
    editor.view.dispatch(tr);
    editor.view.focus();

    const editorElement = this.editor().options.element;

    const targetRect = element.getBoundingClientRect();
    const scrollableParentRect = scrollableParent.getBoundingClientRect();
    const targetPositionRelativeToParent = targetRect.top - scrollableParentRect.top + scrollableParent.scrollTop;
    const editorPositionRelativeToParent =
      editorElement.getBoundingClientRect().top - scrollableParentRect.top + scrollableParent.scrollTop;

    // When computing the active heading TipTip adds an offset equal to the editor's top position in relation to the scrollable parent.
    // We apply the same offset to synchronize the navigation
    const targetPosition = targetPositionRelativeToParent - editorPositionRelativeToParent;

    scrollableParent.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });
  }
}
