import { Injectable } from '@angular/core';
import { ExamdojoSupabaseService, SupabaseBaseHttpService } from '@examdojo/supabase';

@Injectable({
  providedIn: 'root',
})
export class SyllabusHttpService extends SupabaseBaseHttpService<'syllabuses', 'categories'> {
  constructor(protected override readonly supabase: ExamdojoSupabaseService) {
    super(supabase);
  }

  readonly schema = 'categories';
  readonly tableName = 'syllabuses';
}
