import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { TableUpdateModel } from '@examdojo/supabase';
import { sanitizeObject } from '@examdojo/util/sanitize-object';
import { map, switchMap, tap } from 'rxjs';
import { PageVersionHttpService } from './page-version-http.service';
import {
  PAGE_VERSION_UPDATE_ALLOWED_KEYS,
  PageVersionHttpModel,
  PageVersionStoreModel,
  PageVersionUpdateModel,
} from './page-version.model';
import { PageVersionStore } from './page-version.store';

@Injectable({
  providedIn: 'root',
})
export class PageVersionService {
  constructor(
    private readonly store: PageVersionStore,
    private readonly httpService: PageVersionHttpService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  fetchForTopicLevel2(id: PageVersionStoreModel['id']) {
    return this.httpService.fetchForTopicLevel2(id).pipe(
      map((data) => data.map((d) => this.mapHttpModelToStoreModel(d))),
      tap((data) => {
        this.store.set(data);
      }),
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.page_versions' }),
    );
  }

  update(id: PageVersionStoreModel['id'], model: PageVersionUpdateModel) {
    const httpModel = this.mapUpdateModelToHttpUpdateModel(model);

    return this.httpService.update(id, httpModel).pipe(
      tap((data) => {
        this.store.upsert(id, this.mapHttpModelToStoreModel(data));
      }),
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.page_versions', action: 'update' }),
    );
  }

  publishVersion(pageVersion: PageVersionStoreModel) {
    return this.httpService
      .publishVersion({
        page_version_id: pageVersion.id,
        page_type: pageVersion.page_type,
        language_code: pageVersion.language_code,
        topic_level_02_id: pageVersion.topic_level_02_id,
      })
      .pipe(
        this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.page_versions', action: 'update' }),
        switchMap(() =>
          this.fetchForTopicLevel2(pageVersion.topic_level_02_id!).pipe(
            this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.page_versions', action: 'fetch' }),
          ),
        ),
      );
  }

  createDraft(
    args: {
      content: string;
    } & Pick<PageVersionStoreModel, 'page_type' | 'topic_level_02_id' | 'language_code'>,
  ) {
    return this.httpService
      .createDraft({
        content: args.content,
        page_type: args.page_type,
        language_code: args.language_code,
        topic_level_02_id: args.topic_level_02_id,
      })
      .pipe(
        this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.page_versions', action: 'update' }),
        switchMap(() =>
          this.fetchForTopicLevel2(args.topic_level_02_id!).pipe(
            this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.page_versions' }),
          ),
        ),
      );
  }

  setActive(id: PageVersionStoreModel['id']) {
    this.store.setActive(id);
  }

  private mapUpdateModelToHttpUpdateModel(
    updateModel: PageVersionUpdateModel,
  ): TableUpdateModel<'topics_level_03', 'categories'> {
    return sanitizeObject(updateModel, PAGE_VERSION_UPDATE_ALLOWED_KEYS);
  }

  private mapHttpModelToStoreModel(httpModel: PageVersionHttpModel): PageVersionStoreModel {
    return httpModel;
  }
}
