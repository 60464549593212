import { Enum, TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';
import { TopicStatus } from '../model';

export type PageType = Enum<'page_type', 'learn_hub'>;
export type PageStatus = TopicStatus;

export type PageVersionHttpModel = Pick<
  TableRowModel<'page_versions', 'learn_hub'>,
  'content' | 'created_at' | 'id' | 'language_code' | 'page_status' | 'page_type' | 'topic_level_02_id' | 'updated_at'
>;

export type PageVersionStoreModel = PageVersionHttpModel;

export type PageVersionUIModel = PageVersionStoreModel;

export const PAGE_VERSION_CREATE_ALLOWED_KEYS = [
  'content',
  'created_at',
  'id',
  'language_code',
  'page_status',
  'page_type',
  'topic_level_02_id',
  'updated_at',
] satisfies Array<keyof TableInsertModel<'page_versions', 'learn_hub'>>;

export type PageVersionCreateModel = Pick<
  TableInsertModel<'page_versions', 'learn_hub'>,
  (typeof PAGE_VERSION_CREATE_ALLOWED_KEYS)[number]
>;

export const PAGE_VERSION_UPDATE_ALLOWED_KEYS = [...PAGE_VERSION_CREATE_ALLOWED_KEYS] satisfies Array<
  keyof TableUpdateModel<'page_versions', 'learn_hub'>
>;

export type PageVersionUpdateModel = Pick<
  TableUpdateModel<'page_versions', 'learn_hub'>,
  (typeof PAGE_VERSION_UPDATE_ALLOWED_KEYS)[number]
>;
