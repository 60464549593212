@for (item of tableOfContentData(); track item.id; let idx = $index) {
  <div
    class="item rounded py-1.5 hover:bg-blue-50"
    [ngClass]="{ 'is-active': item.isActive, 'is-scrolled-over': item.isScrolledOver }"
    [style.--level]="item.level"
  >
    <a (click)="onItemClick(item.id)" [attr.data-toc-id]="item.id" [attr.data-item-index]="item.itemIndex">
      <dojo-markdown-viewer [content]="item.textContent" class="latex-sample" />
    </a>
  </div>
}
