import { NgModule } from '@angular/core';
import { DateTimeModule } from '../date-time.module';
import { DurationComponent } from './duration.component';

@NgModule({
  imports: [DateTimeModule],
  declarations: [DurationComponent],
  exports: [DurationComponent],
})
export class DurationModule {}
