import { Injectable } from '@angular/core';
import { SupabaseBaseHttpService } from '@examdojo/supabase';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsHttpService extends SupabaseBaseHttpService<'user_settings'> {
  override readonly id_field = 'user_id';
  readonly schema = 'public';
  readonly tableName = 'user_settings';
}
