import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { mapToVoid } from '@examdojo/rxjs';
import { assertNonNullable } from '@examdojo/util/assert';
import { combineLatest, first, tap } from 'rxjs';
import { TopicLevel1Query, TopicLevel1Service, TopicLevel2Query, TopicLevel2Service } from './index';

export function getActiveTopicsResolver(params: {
  topicLevel1SlugParam: string;
  topicLevel2SlugParam: string;
}): ResolveFn<void> {
  return (route) => {
    const topicLevel1Service = inject(TopicLevel1Service);
    const topicLevel2Service = inject(TopicLevel2Service);
    const topicLevel1Query = inject(TopicLevel1Query);
    const topicLevel2Query = inject(TopicLevel2Query);

    const topicLevel1Slug = route.paramMap.get(params.topicLevel1SlugParam);
    const topicLevel2Slug = route.paramMap.get(params.topicLevel2SlugParam);

    assertNonNullable(topicLevel1Slug, 'topicLevel1Slug');
    assertNonNullable(topicLevel2Slug, 'topicLevel2Slug');

    return combineLatest([topicLevel1Query.entities$, topicLevel2Query.entities$]).pipe(
      first(
        ([topicLevel1Entities, topicLevel2Entities]) => !!topicLevel1Entities.length && !!topicLevel2Entities.length,
      ),
      tap(() => {
        topicLevel1Service.setActiveBySlug(topicLevel1Slug);
        topicLevel2Service.setActiveBySlug(topicLevel2Slug);
      }),
      mapToVoid(),
    );
  };
}
