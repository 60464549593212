import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

export const enum MouseButton {
  Primary = 0,
  Wheel = 1,
  Secondary = 2,
  BackButton = 3,
  ForwardButton = 4,
}

/**
 * The operation system.
 */
export enum OperatingSystem {
  Windows = 'Windows',
  MacOS = 'MacOS',
  Linux = 'Linux',
  Android = 'Android',
  IOS = 'IOS',
  Other = 'Other',
}

/**
 * The browser rendering engine.
 *
 * Chrome is either `WebKit` (iOS) or `Blink` for other OS.
 */
export enum Browser {
  WebKit = 'WebKit',
  Firefox = 'Firefox',
  Edge = 'Edge',
  Safari = 'Safari',
  Blink = 'Blink',
  Other = 'Other',
}

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(private readonly platform: Platform) {}

  public readonly isBrowser = this.platform.isBrowser;

  public readonly operatingSystem = this.guessOperatingSystem();
  public readonly browser = this.guessBrowser();

  isPrimaryMouseButtonEvent(e: MouseEvent): boolean {
    if (e.button !== MouseButton.Primary) {
      return false;
    }

    // On MacBook touch pads the right click can be simulated by pressing ctrl key while clicking the primary button (touchpad).
    if (this.operatingSystem === OperatingSystem.MacOS && e.ctrlKey) {
      return false;
    }

    return true;
  }

  isSecondaryMouseButtonEvent(e: MouseEvent): boolean {
    if (e.button === MouseButton.Secondary) {
      return true;
    }

    if (this.operatingSystem === OperatingSystem.MacOS && e.ctrlKey && e.button === MouseButton.Primary) {
      return true;
    }

    return false;
  }

  eventHasCmdOrCtrlModifier(event: KeyboardEvent | MouseEvent): boolean {
    return (
      (event.metaKey && this.operatingSystem === OperatingSystem.MacOS) ||
      (event.ctrlKey && this.operatingSystem !== OperatingSystem.MacOS)
    );
  }

  private guessOperatingSystem(): OperatingSystem {
    if (this.platform.ANDROID) {
      return OperatingSystem.Android;
    }

    if (this.platform.IOS) {
      return OperatingSystem.IOS;
    }

    if (/windows/i.test(navigator.userAgent)) {
      return OperatingSystem.Windows;
    }

    if (/Macintosh/i.test(navigator.userAgent)) {
      return OperatingSystem.MacOS;
    }

    if (/Linux/i.test(navigator.userAgent)) {
      return OperatingSystem.Linux;
    }

    return OperatingSystem.Other;
  }

  private guessBrowser(): Browser {
    if (this.platform.EDGE) {
      return Browser.Edge;
    }

    if (this.platform.FIREFOX) {
      return Browser.Firefox;
    }

    if (this.platform.SAFARI) {
      return Browser.Safari;
    }

    if (this.platform.WEBKIT) {
      return Browser.WebKit;
    }

    if (this.platform.BLINK) {
      return Browser.Blink;
    }

    return Browser.Other;
  }
}
