import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import { from, Observable } from 'rxjs';
import { BillingHttpModel } from './billing.model';

export interface BillingSessionResult {
  result: {
    status: 'success';
    link: string;
  };
}

@Injectable({ providedIn: 'root' })
export class BillingHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly supabaseService: ExamdojoSupabaseService,
  ) {}

  private readonly baseUrl = environment.examdojo.billingApiUrl;

  getCheckoutSession() {
    return this.http.post<BillingSessionResult>(`${this.baseUrl}/checkout-session`, {
      success_url: window.location.href,
      cancel_url: window.location.href,
    });
  }

  getCustomerPortalSession() {
    return this.http.post<BillingSessionResult>(`${this.baseUrl}/customer-portal-session`, {
      return_url: window.location.href,
    });
  }

  getActiveSubscription(userId: string): Observable<BillingHttpModel | null> {
    return from(
      this.supabaseService.client
        .from('user_subscriptions')
        .select()
        .eq('user_id', userId)
        .maybeSingle()
        .throwOnError()
        .then((response) => response.data!),
    );
  }
}
