import { CheckForExcess, CheckForMissing } from '@examdojo/core/typescript';
import { Enum } from '@examdojo/supabase';

export type UserOrigin = Enum<'user_origin'>;

export const USER_ORIGINS = [
  'friends_family',
  'teacher',
  'google_search',
  'youtube',
  'tiktok',
  'reddit',
  'instagram',
  'facebook',
  'linkedin',
  'other',
] as const satisfies UserOrigin[];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MissingCheck = CheckForMissing<typeof USER_ORIGINS, UserOrigin>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExcessCheck = CheckForExcess<typeof USER_ORIGINS, UserOrigin>;
