<y42-icon *ngIf="iconName" [icon]="iconName" [ngClass]="iconColor" class="mb-0.5" [size]="isIconBig ? 64 : 32" />
<h3 *ngIf="header" class="empty-state-header">{{ header }}</h3>
<ng-content select="[complexHeader]"></ng-content>
<span *ngIf="body" class="empty-state-body text-center">
  {{ body }}
</span>
<ng-content select="[complexContent]"></ng-content>

<ng-container *ngIf="state.addButtonLabelTemplate; else defaultButtonTmpl">
  <ng-container [ngTemplateOutlet]="state.addButtonLabelTemplate"></ng-container>
</ng-container>

<ng-template #defaultButtonTmpl>
  <button *ngIf="addButtonLabel" mat-flat-button type="button" (click)="add.emit()">
    {{ addButtonLabel }}
  </button>
</ng-template>
