import { ChangeDetectionStrategy, Component, ElementRef, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { connectState } from '@examdojo/angular/util';
import { PageType, PageVersionQuery } from '@examdojo/category/v2';
import { DateTimeModule } from '@examdojo/core/date-time';
import { EmptyStateComponent } from '@examdojo/core/empty-state';
import { TiptapEditorComponent, TiptapTableOfContentsComponent } from '@examdojo/core/tiptap';
import { LoaderComponent } from '@examdojo/ui/loader';
import { TranslocoPipe } from '@jsverse/transloco';
import { map, merge, startWith, switchMap, tap } from 'rxjs';

@Component({
  selector: 'dojo-topic-concept-tab',
  standalone: true,
  imports: [
    TiptapEditorComponent,
    LoaderComponent,
    DateTimeModule,
    EmptyStateComponent,
    TranslocoPipe,
    TiptapTableOfContentsComponent,
  ],
  templateUrl: './topic-tab.component.html',
  styleUrl: './topic-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class TopicTabComponent implements OnInit {
  constructor(
    private readonly pageVersionQuery: PageVersionQuery,
    private readonly elementRef: ElementRef,
  ) {
    merge(
      this.activePageVersion$.pipe(
        map((page) => page?.content),
        tap((content) => {
          this.contentCtrl.setValue(content ?? '');
        }),
      ),
    )
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  readonly scrollableContainer = signal<HTMLElement | undefined>(undefined);

  readonly pageType = input.required<PageType>();

  readonly activePageVersion$ = toObservable(this.pageType).pipe(
    switchMap((pageType) => this.pageVersionQuery.getPublishedVersionForType(pageType)),
    startWith(undefined),
  );

  readonly contentCtrl = new FormControl<string>(``, { nonNullable: true });

  readonly state = connectState({
    activePageVersion: this.activePageVersion$,
  });

  ngOnInit() {
    this.scrollableContainer.set(this.elementRef.nativeElement.closest('.main-content-area') as HTMLElement);
  }
}
