export enum CalloutType {
  Note = 'note',
  Tip = 'tip',
  Example = 'example',
  CommonMistake = 'common_mistake',
}

export const CALLOUT_TYPE_TO_ICON: Record<CalloutType, string> = {
  [CalloutType.Note]: 'assets/images/callout/note-icon.svg',
  [CalloutType.Tip]: 'assets/images/callout/tip-icon.svg',
  [CalloutType.Example]: 'assets/images/callout/example-icon.svg',
  [CalloutType.CommonMistake]: 'assets/images/callout/mistake-icon.svg',
};

export const CALLOUT_TYPE_TO_LABEL: Record<CalloutType, string> = {
  [CalloutType.Note]: 'Note',
  [CalloutType.Tip]: 'Tip',
  [CalloutType.Example]: 'Example️',
  [CalloutType.CommonMistake]: 'Common mistake️',
};
