import { Optional } from '@examdojo/core/typescript';
import { ExtendedKeyboardEvent } from 'mousetrap';

export enum KeyboardShortcutsNamespace {
  Global = 'global',
  Git = 'git',
  BottomDrawer = 'bottom_drawer',
  Sql = 'sql_queryable',
  PythonEditable = 'python_editable',
  AssetHealth = 'asset_health',
}

export interface KeySequenceStored {
  id: string;
  sequence: string;
  callback: (e: ExtendedKeyboardEvent, combo: string) => boolean | void | Promise<boolean | void>;
  namespace: KeyboardShortcutsNamespace;
  name?: string;
  description?: string;
}

export interface KeySequence extends Omit<Omit<KeySequenceStored, 'sequence'>, 'namespace'> {
  sequence: string | string[];
  namespace?: KeyboardShortcutsNamespace;
}

export interface NamedKeySequence extends KeySequence {
  name: string;
}

export type InputKeySequence = Omit<Optional<NamedKeySequence, 'callback'>, 'id'>;
export type AdHocInputKeySequence = NamedKeySequence;

export type KeyboardShortcutDefinitionSet<T extends string, U extends object = {}> = Partial<
  Record<T, InputKeySequence & U>
>;

export type RegisteredKeyboardShortcutDefinitionSet<T extends string, U extends object = {}> = Partial<
  Record<T, NamedKeySequence & U>
>;

export enum ModifierKey {
  Shift = 'shift',
  Ctrl = 'ctrl',
  Alt = 'alt',
  /**
   * Alias for `alt`
   */
  Option = 'option',
  /**
   * ⌘ on Mac, ⊞ (Win) on Windows
   */
  Meta = 'meta',
  /**
   * Alias for `meta`
   */
  Command = 'command',
  /**
   * Equals `command` on Mac and `ctrl` on Windows/Linux
   */
  Mod = 'mod',
}
