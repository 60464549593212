import {
  ChangeDetectionStrategy,
  Component,
  contentChild,
  Input,
  TemplateRef,
  TrackByFunction,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import {
  _SelectComponent,
  _SingleSelectComponent,
  SelectOption,
  SelectOptionDirective,
  SelectOptionDisabledFn,
  SelectOptionLabelFn,
  SelectOptionValueFn,
  SelectTriggerDirective,
  SingleSelectComponent,
  trackBySelectItemValue,
} from '@examdojo/core/select';
import { isNotNullish } from '@examdojo/util/nullish';
import { Platform } from '@ionic/angular/standalone';
import { combineLatest, filter, map, merge, tap } from 'rxjs';
import { ActionSheetSelectComponent } from '../../action-sheet/action-sheet-select.component';
import { ButtonExpand, ButtonFill } from '../button.component';

@Component({
  selector: 'dojo-select-button',
  standalone: true,
  imports: [SingleSelectComponent],
  templateUrl: './select-button.component.html',
  styleUrl: './select-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectButtonComponent<Option extends object = SelectOption, Value = unknown> {
  constructor(private readonly platform: Platform) {
    merge(this.setTriggerTemplate(), this.setOptionTemplate()).pipe(takeUntilDestroyed()).subscribe();
  }

  readonly isMobile = this.platform.is('mobile');

  @Input({ required: true }) formCtrl!: FormControl<Value>;
  @Input() options?: Option[] | null;
  @Input() optionValue?: keyof Option | SelectOptionValueFn<Option>;
  @Input() optionLabel?: keyof Option | SelectOptionLabelFn<Option>;
  @Input() optionDisabled?: keyof Option | SelectOptionDisabledFn<Option>;
  @Input() label?: string | TemplateRef<unknown> | null;
  @Input() hideErrors = false;
  @Input() errorMessages?: Record<string, string>;
  @Input() required = false;
  @Input() color?: MatFormField['color'];
  @Input() tooltipHint?: string;
  @Input() compact? = false;
  @Input() micro? = false;
  @Input() borderless? = false;
  @Input() trackBy: TrackByFunction<Option & SelectOption> = trackBySelectItemValue;
  @Input() fullWidthField = false;
  @Input() nativeSelect = this.isMobile;

  // MatSelect properties
  @Input() compareWith?: MatSelect['compareWith'];
  @Input() panelClass: MatSelect['panelClass'] = 'custom-mat-select-panel';
  @Input() placeholder?: MatSelect['placeholder'];
  @Input() hideSingleSelectionIndicator?: MatSelect['hideSingleSelectionIndicator'] = true;
  // ---

  // Button inputs
  @Input() expand?: ButtonExpand;
  @Input() small = false;
  @Input() fill: ButtonFill = 'solid';

  private readonly singleSelectComponent = viewChild(_SingleSelectComponent);
  private readonly actionSheetComponent = viewChild(ActionSheetSelectComponent);
  private readonly selectComponents$ = combineLatest([
    toObservable(this.singleSelectComponent),
    toObservable(this.actionSheetComponent),
  ]).pipe(map((components: Array<_SelectComponent | undefined>) => components.filter(isNotNullish)));

  private readonly selectOptionDirective = contentChild(SelectOptionDirective);
  private readonly selectOptionDirective$ = toObservable(this.selectOptionDirective).pipe(filter(isNotNullish));

  private readonly selectTriggerDirective = contentChild(SelectTriggerDirective);
  private readonly selectTriggerDirective$ = toObservable(this.selectTriggerDirective).pipe(filter(isNotNullish));

  private setTriggerTemplate() {
    return combineLatest([this.selectComponents$, this.selectTriggerDirective$]).pipe(
      tap(([selectComponents, selectTriggerDirective]) =>
        selectComponents.forEach(
          (component: _SelectComponent) => (component.triggerTemplate = selectTriggerDirective.templateRef),
        ),
      ),
    );
  }

  private setOptionTemplate() {
    return combineLatest([this.selectComponents$, this.selectOptionDirective$]).pipe(
      tap(([selectComponents, selectOptionDirective]) =>
        selectComponents.forEach((component) => (component.optionTemplate = selectOptionDirective.templateRef)),
      ),
    );
  }
}
