import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { LocalizedString } from '../localized-string.model';
import { LocalizePipe } from './localize.pipe';

@Component({
  selector: 'dojo-localized-string',
  standalone: true,
  imports: [LocalizePipe, AsyncPipe],
  template: ` {{ content() | localize | async }} `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalizedStringComponent {
  readonly content = input.required<LocalizedString>();
}
