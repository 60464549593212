import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { QueryEntity } from '@examdojo/state';
import { TopicLevel1Query } from '../topic-level-1';
import { TopicLevel2StoreModel, TopicLevel2UIModel } from './topic-level-2.model';
import { TopicLevel2State, TopicLevel2Store } from './topic-level-2.store';

@Injectable({ providedIn: 'root' })
export class TopicLevel2Query extends QueryEntity<
  TopicLevel2State,
  TopicLevel2StoreModel,
  TopicLevel2UIModel,
  'id',
  number
> {
  constructor(
    protected override store: TopicLevel2Store,
    private readonly topicLevel1Query: TopicLevel1Query,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {
    super(store, () => [topicLevel1Query.selectAllEntities()]);
  }

  override readonly toUIModelFn = (storeModel: TopicLevel2StoreModel): TopicLevel2UIModel => {
    const codeNumber = storeModel.code.split(' ').at(-1);

    if (!codeNumber) {
      this.errorHandlerService.error(`[TopicLevel2Query.toUIModelFn]: Cannot extract code number from code`, {
        context: { codeNumber, storeModel },
      });
    }

    const topicLevel1 = this.topicLevel1Query.getUIEntity(storeModel.topic_level_01_id)!;

    return {
      ...storeModel,
      codeNumber: codeNumber ?? '',
      topicLevel1,
    };
  };
}
