import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { connectState, InputObservable, trackByValue } from '@examdojo/angular/util';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgForOf, NgIf } from '@angular/common';
import { KeySequence } from '../keyboard-shortcuts.model';
import { KeyboardShortcutsService } from '../keyboard-shortcuts.service';

@UntilDestroy()
@Component({
  selector: 'y42-keyboard-shortcut-sequence',
  standalone: true,
  imports: [NgForOf, NgIf],
  templateUrl: './keyboard-shortcut-sequence.component.html',
  styleUrls: ['./keyboard-shortcut-sequence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyboardShortcutSequenceComponent {
  constructor(private readonly keyboardShortcutsService: KeyboardShortcutsService) {}

  @Input({ required: true })
  @InputObservable()
  sequence!: KeySequence['sequence'];
  private readonly sequence$!: Observable<KeySequence['sequence'] | undefined>;

  /**
   * Number of shortcuts to display.
   * @default Infinity - Show all shortcuts
   */
  @Input()
  @InputObservable()
  limitTo = Infinity;
  private readonly limitTo$!: Observable<number>;

  readonly state = connectState({
    labels: combineLatest([this.sequence$, this.limitTo$]).pipe(
      map(([sequence, limitTo]) => {
        if (!sequence) {
          return [];
        }
        const bound = isFinite(limitTo) ? limitTo : undefined;
        return this.keyboardShortcutsService.getHtmlLabelsForSequence(sequence).slice(0, bound);
      }),
    ),
  });

  protected readonly trackByValue = trackByValue;
}
