import { Icon } from '@examdojo/core/icon';

export enum Features {
  TopicPractice = 'practice',
  TopicPracticeV2 = 'practice-v2',
  LearnHub = 'learn-hub',
  Assessments = 'assessments',
  Leaderboards = 'leaderboards',
  Home = 'home',
}

export const FEATURE_TO_LABEL_KEY: Record<Features, string> = {
  [Features.LearnHub]: 'pages.learn',
  [Features.TopicPractice]: 'pages.practice',
  [Features.TopicPracticeV2]: 'pages.practice-v2',
  [Features.Assessments]: 'pages.assessments',
  [Features.Leaderboards]: 'pages.leaderboards',
  [Features.Home]: 'pages.home',
};

export const FEATURE_TO_ICON: Record<Features, Icon> = {
  [Features.LearnHub]: 'assets/images/features/learn.svg',
  [Features.TopicPractice]: 'assets/images/features/topic-practice.svg',
  [Features.TopicPracticeV2]: 'assets/images/features/topic-practice.svg',
  [Features.Assessments]: 'assets/images/features/assessments.svg',
  [Features.Leaderboards]: 'assets/images/features/leaderboards.svg',
  [Features.Home]: 'assets/images/features/home.svg',
};
