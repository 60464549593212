import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeatureFlagDirective } from '@examdojo/core/feature-flag';
import { ExamDojoFeatureFlag } from '@examdojo/models/feature-flag';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { IonHeader, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { BillingInfoComponent } from '../billing.component';

@Component({
  selector: 'dojo-billing-error-handling-dialog',
  standalone: true,
  template: `
    <ion-header [translucent]="true" class="shadow-none">
      <ion-toolbar class="toolbar-with-closing-button !px-4">
        <div slot="start" class="flex">
          <dojo-icon-button (click)="closeDialog()" [icon]="'times'"></dojo-icon-button>
        </div>
        <div slot="end" class="w-8"></div>
        <ion-title class="font-primary-exam font-medium-exam text-center text-sm text-stone-800">
          {{ 'examdojo.billing_limit_reached' | transloco }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <div class="flex flex-col p-4">
      <div class="mb-6 text-sm text-stone-800">{{ errorMessage }}</div>
      <dojo-billing-info *ngIfFeatureFlag="ExamDojoFeatureFlag.BillingUi" />
    </div>
  `,
  imports: [
    IconButtonComponent,
    TranslocoPipe,
    IonTitle,
    IonToolbar,
    IonHeader,
    BillingInfoComponent,
    FeatureFlagDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingErrorHandlingDialogComponent {
  constructor(private readonly modalController: ModalController) {}

  @Input({ required: true }) errorMessage!: string;
  readonly ExamDojoFeatureFlag = ExamDojoFeatureFlag;

  closeDialog() {
    this.modalController.dismiss();
  }
}
