import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeFormat, Datestring } from './date-time.model';
import { DateTimeService } from './date-time.service';

@Pipe({ name: 'formatDate', standalone: true })
export class FormatDatePipe implements PipeTransform {
  constructor(private readonly dateTimeService: DateTimeService) {}

  transform(date: Datestring | undefined, format: DateTimeFormat = DateTimeFormat.ShortDate): string {
    if (!date) {
      return '';
    }

    return this.dateTimeService.formatDate(date, format);
  }
}
