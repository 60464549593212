import { Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { UserSettings } from '@examdojo/models/user';
import { mapToVoid } from '@examdojo/rxjs';
import { TableUpdateModel } from '@examdojo/supabase';
import { assertNonNullable } from '@examdojo/util/assert';
import { filter, map, Observable, switchMap, tap } from 'rxjs';
import { UserSettingsHttpService } from './user-settings-http.service';
import { UserQuery } from './user.query';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  constructor(
    private readonly userSettingsHttpService: UserSettingsHttpService,
    private readonly userQuery: UserQuery,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {
    this.updateOnUserStateChange().pipe(takeUntilDestroyed()).subscribe();
  }

  private readonly value = signal<UserSettings>({});
  private readonly value$ = toObservable(this.value);

  readonly notificationsSettings$ = this.value$.pipe(map((value) => value.notifications));

  load(): Observable<void> {
    return this.userQuery.active$.pipe(
      filter(Boolean),
      switchMap((active) => this.userSettingsHttpService.fetch(active.id)),
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'user' }),
      tap((settings) => {
        this.value.set(settings as UserSettings);
      }),
      mapToVoid(),
    );
  }

  save(storage: UserSettings): Observable<UserSettings>;
  save(key: keyof UserSettings, value: UserSettings[keyof UserSettings]): Observable<UserSettings>;
  save<T extends keyof UserSettings, U extends T | UserSettings>(
    keyOrValue: U,
    value?: U extends T ? UserSettings[T] : never,
  ): Observable<UserSettings> {
    const payload: UserSettings = typeof keyOrValue === 'string' ? { [keyOrValue]: value } : keyOrValue;
    const updatedValue = { ...this.value(), ...payload } as const;
    return this.update(updatedValue);
  }

  delete(key: keyof UserSettings): Observable<UserSettings> {
    const { [key]: _, ...updatedValue } = this.value();
    return this.update(updatedValue);
  }

  private updateOnUserStateChange() {
    return this.userQuery.selectActive().pipe(
      filter(Boolean),
      map((user) => (user.storage ?? {}) as UserSettings),
      tap((value) => {
        this.value.set(value);
      }),
    );
  }

  private update(payload: UserSettings): Observable<UserSettings> {
    const activeUser = this.userQuery.getActive();
    assertNonNullable(activeUser, 'activeUser');

    return this.userSettingsHttpService.update(activeUser.id, payload as TableUpdateModel<'user_settings'>).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'user', action: 'update' }),
      tap(() => {
        this.value.set(payload);
      }),
      map(() => payload),
    );
  }
}
