import { ModifierKey } from './keyboard-shortcuts.model';

export const HardModifierKeys = [ModifierKey.Ctrl, ModifierKey.Command, ModifierKey.Meta, ModifierKey.Mod];
export const SoftModifierKeys = [ModifierKey.Shift, ModifierKey.Alt, ModifierKey.Option];

export function isMultiKeyCombo(combo: string): boolean {
  return combo.split(' ').length > 1;
}

function extractCombo(combo: string): { combo: string; modifiers: ModifierKey[]; keys: string[] } {
  const elements = combo.split('+');

  const modifiers = elements.filter((key): key is ModifierKey =>
    Object.values(ModifierKey).includes(key as ModifierKey),
  );

  const keys = elements.filter((key) => !modifiers.includes(key as ModifierKey));

  return { combo, modifiers, keys };
}

export function isShortcutComboInputSafe(combo: string): boolean {
  if (isMultiKeyCombo(combo)) {
    return combo.split(' ').every(isShortcutComboInputSafe);
  }

  const { modifiers } = extractCombo(combo);
  return modifiers.some((modifier) => HardModifierKeys.includes(modifier));
}

export function isEditableElement(element: Element) {
  return (
    element.tagName === 'INPUT' ||
    element.tagName === 'SELECT' ||
    element.tagName === 'TEXTAREA' ||
    (!!(element as HTMLElement).contentEditable && (element as HTMLElement).contentEditable === 'true')
  );
}
