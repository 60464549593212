import { ChangeDetectorRef, DestroyRef, Directive, EventEmitter, HostListener, input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { BaseButton } from '@examdojo/core/button';
import { markFormAsSubmitted } from '@examdojo/core/form';
import { FormConfirmFnReturnType } from '@examdojo/core/form-submit-button';
import { isNullish } from '@examdojo/util/nullish';
import { filter, firstValueFrom, isObservable, merge, tap, timer } from 'rxjs';
import { SwiperStepperComponent } from './swiper-stepper.component';

@Directive({
  selector: '[swiperStepperNext]',
  standalone: true,
})
export class SwiperStepperNextDirective {
  constructor(
    private readonly button: BaseButton,
    private readonly swiperStepperComponent: SwiperStepperComponent<FormGroup>,
    private readonly cdRef: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef,
  ) {
    merge(
      this.changeButtonTypeFromSlide(),
      this.disableOnActiveControlStatusChange(),
      this.swiperStepperComponent.goNextTrigger$.pipe(tap(({ force }) => this.goNextOrSubmit({ force }))),
    )
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  readonly enabled = input(true, {
    alias: 'swiperStepperNext',
    transform: (value?: boolean | ''): boolean => {
      if (isNullish(value) || value === '') {
        return true;
      }
      return value;
    },
  });

  @Output() triggered = new EventEmitter();
  @Output() submitted = new EventEmitter<unknown>();

  private pending = false;

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.enabled()) {
      return;
    }

    this.goNextOrSubmit();
  }

  private goNextOrSubmit({ force }: { force: boolean } = { force: false }) {
    const swiperInstance = this.swiperStepperComponent.swiperInstance()!;

    if (swiperInstance.isEnd) {
      this.submitForm({ checkFormValidity: !force });
      return;
    }

    const currentIndex = swiperInstance.activeIndex;
    const slides = this.swiperStepperComponent.slides();
    const nextSlideIndex = slides.findIndex((slide, index) => index > currentIndex && !slide.skip());

    if (nextSlideIndex === -1) {
      return;
    }

    timer(this.swiperStepperComponent.goNextDelayMs ?? 0)
      .pipe(
        tap(() => {
          swiperInstance.slideTo(nextSlideIndex);
          this.triggered.emit();
        }),
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private changeButtonTypeFromSlide() {
    return this.swiperStepperComponent.isLastStep$.pipe(
      tap((isLastStep) => {
        this.button.type.set(isLastStep ? 'submit' : 'button');
      }),
    );
  }

  private disableOnActiveControlStatusChange() {
    return this.swiperStepperComponent.activeControlStatus$.pipe(
      filter(() => this.enabled()),
      filter(Boolean),
      tap((status) => {
        this.button.disabled.set(status !== 'VALID');
      }),
    );
  }

  private async submitForm({ checkFormValidity }: { checkFormValidity: boolean } = { checkFormValidity: true }) {
    if (this.pending) {
      return;
    }

    this.setInProgress(true);
    const form = this.swiperStepperComponent.form();
    await markFormAsSubmitted(form);

    if (checkFormValidity && !form.valid) {
      this.setInProgress(false);
      return;
    }

    try {
      const confirmFn = this.swiperStepperComponent.submitFn();
      const asyncFnOrResult = confirmFn(form);
      let result: FormConfirmFnReturnType = asyncFnOrResult;

      if (isObservable(asyncFnOrResult)) {
        result = await firstValueFrom(asyncFnOrResult.pipe(takeUntilDestroyed(this.destroyRef)), {
          defaultValue: undefined,
        });
      } else {
        result = await asyncFnOrResult;
      }

      this.triggered.emit();
      this.submitted.emit(result);
    } catch (error) {
      console.error(error);
    } finally {
      this.setInProgress(false);
    }
  }

  private setInProgress(pending: boolean) {
    this.button.pending = pending;
    this.pending = pending;
    this.cdRef.markForCheck();
  }
}
