import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { Store } from './store';

@Injectable()
export abstract class Query<State extends object = object> {
  constructor(protected readonly store: Store<State>) {}

  getValue() {
    return this.store.getValue();
  }

  select<T extends keyof State>(property: T): Observable<State[T]> {
    return this.store.pipe(select((state) => state[property]));
  }
}
