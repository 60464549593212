import { Injectable } from '@angular/core';
import { AbstractDialogService } from '@examdojo/core/dialog';
import { isPostgrestError } from '@examdojo/models/error-handler';
import { BillingErrorHandlingDialogComponent } from './billing-error-handling-dialog.component';
import { isBillingLimitsExceededErrorCode } from './limits-exceeding-error-handling.model';

@Injectable({
  providedIn: 'root',
})
export class BillingErrorHandlingService extends AbstractDialogService<void, boolean> {
  readonly id = 'billing-error-handling-dialog';
  protected override readonly component = BillingErrorHandlingDialogComponent;

  handleBillingLimitsExceededError(err: unknown) {
    if (isPostgrestError(err) && isBillingLimitsExceededErrorCode(err.code)) {
      this.openDialog({
        options: {
          componentProps: { errorMessage: err.message },
        },
      });
    }
  }
}
