import { animate, state, style, transition, trigger } from '@angular/animations';
import { AngularMaterialAnimationFunction, AnimationDuration } from './animations.model';

interface SlideAnimationOptions {
  initial?: string;
  transformed?: string;
}

interface SlideAnimationConfig {
  additionalStyles?: { [key: string]: number | string };
  transformX?: SlideAnimationOptions;
}

export const getAnimationSlide = (
  transformX: { initial: string; transformed: string },
  additionalStyles?: { [key: string]: number | string },
) => {
  return [
    state('false', style({ transform: `translateX(${transformX.initial})` })),
    state('true', style({ transform: `translateX(${transformX.transformed})` })),

    transition(':enter, false => true', [
      style({ transform: `translateX(${transformX.initial})` }),
      animate(
        `${AnimationDuration.Medium}ms ${AngularMaterialAnimationFunction}`,
        style({ transform: `translateX(${transformX.transformed})` }),
      ),
    ]),
    transition(':leave, true => false', [
      style({ transform: `translateX(${transformX.transformed})`, ...additionalStyles }),
      animate(
        `${AnimationDuration.Medium}ms ${AngularMaterialAnimationFunction}`,
        style({ transform: `translateX(${transformX.initial})` }),
      ),
    ]),
  ];
};

export const getAnimationSlideInToRight = (options?: SlideAnimationConfig) => {
  const { additionalStyles = {}, transformX } = options || {};
  const initial = transformX?.initial || '-100%';
  const transformed = transformX?.transformed || '0';

  return trigger('slideInToRight', getAnimationSlide({ initial, transformed }, additionalStyles));
};

export const getAnimationSlideInToLeft = (options?: SlideAnimationConfig) => {
  const initial = options?.transformX?.initial || '100%';
  const transformed = options?.transformX?.transformed || '0';

  return trigger('slideInToLeft', getAnimationSlide({ initial, transformed }));
};
