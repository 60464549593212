import { NgForOf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InputObservable, connectState, trackByValue } from '@examdojo/angular/util';
import { Observable, map } from 'rxjs';
import { KeyboardShortcutsService } from './keyboard-shortcuts.service';

export type KeyboardShortcut = 'CmdEnter' | 'CmdK' | 'CmdShiftEnter';

@UntilDestroy()
@Component({
  selector: 'y42-keyboard-shortcut',
  standalone: true,
  imports: [NgForOf],
  template: `
    <ng-container *ngFor="let key of state.shortcut; trackBy: trackByValue">
      <kbd [class]="shortcutClass">{{ key }}</kbd>
    </ng-container>
  `,
  styles: [
    `
      :host {
        @apply ml-2 gap-0.5;
        display: flex;
      }

      kbd {
        @apply rounded-micro p-1;
        display: inline-flex;
        min-width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.3);
        text-transform: uppercase;
      }
    `,
  ],
})
export class KeyboardShortcutComponent {
  constructor(private readonly keyboardShortcutsService: KeyboardShortcutsService) {}

  @Input()
  @InputObservable()
  shortcut: KeyboardShortcut | string[] = 'CmdEnter';
  private readonly shortcut$!: Observable<KeyboardShortcut | string[]>;

  @Input() shortcutClass = '';

  private readonly keyboardShortcutToSymbols: { CmdK: string[]; CmdEnter: string[]; CmdShiftEnter: string[] } = {
    CmdEnter: this.getShortcutLabels('command+enter'),
    CmdShiftEnter: this.getShortcutLabels('command+shift+enter'),
    CmdK: this.getShortcutLabels('command+k'),
  };

  readonly trackByValue = trackByValue;

  readonly state = connectState({
    shortcut: this.shortcut$.pipe(
      map((shortcut) => {
        if (typeof shortcut === 'string') {
          return this.keyboardShortcutToSymbols[shortcut];
        }

        return this.getShortcutLabels(shortcut.join('+'));
      }),
    ),
  });

  private getShortcutLabels(sequence: string) {
    return this.keyboardShortcutsService.getLabels(sequence).map((key) => key.decorator ?? key.base);
  }
}
