import { Injectable } from '@angular/core';
import { QueryEntity } from '@examdojo/state';
import { SyllabusQuery } from '../syllabus';
import { TopicLevel1StoreModel, TopicLevel1UIModel } from './topic-level-1.model';
import { TopicLevel1State, TopicLevel1Store } from './topic-level-1.store';

@Injectable({ providedIn: 'root' })
export class TopicLevel1Query extends QueryEntity<
  TopicLevel1State,
  TopicLevel1StoreModel,
  TopicLevel1UIModel,
  'id',
  number
> {
  constructor(
    protected override store: TopicLevel1Store,
    private readonly syllabusQuery: SyllabusQuery,
  ) {
    super(store, () => [syllabusQuery.selectAllEntities()]);
  }

  override readonly toUIModelFn = (entity: TopicLevel1StoreModel): TopicLevel1UIModel => {
    const syllabus = this.syllabusQuery.getUIEntity(entity.syllabus_id)!;

    return { ...entity, syllabus };
  };
}
