import { CheckForExcess, CheckForMissing } from '@examdojo/core/typescript';
import { Enum } from '@examdojo/supabase';

export type CourseLevel = Enum<'course_level', 'categories'>;

export const COURSE_LEVELS = ['HL', 'SL'] as const satisfies CourseLevel[];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MissingCheck = CheckForMissing<typeof COURSE_LEVELS, CourseLevel>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExcessCheck = CheckForExcess<typeof COURSE_LEVELS, CourseLevel>;
