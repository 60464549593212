import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { SyllabusService } from '@examdojo/category/v2';
import { mapToVoid } from '@examdojo/rxjs';
import { combineLatest, filter, first, map, tap } from 'rxjs';
import { UserQuery } from '../../user/user.query';

export const syllabusesResolver: ResolveFn<void> = () => {
  const syllabusService = inject(SyllabusService);
  const userQuery = inject(UserQuery);
  const userSyllabusId$ = userQuery.active$.pipe(
    filter(Boolean),
    map((user) => user.syllabus_id),
  );

  return combineLatest([syllabusService.fetchSyllabuses().pipe(first()), userSyllabusId$]).pipe(
    first(),
    tap(([syllabuses, userSyllabusId]) => {
      if (!syllabuses.length) {
        return;
      }

      if (userSyllabusId && syllabuses.some(({ id }) => id === userSyllabusId)) {
        syllabusService.setActive(userSyllabusId);
        return;
      }

      syllabusService.setActive(syllabuses[0].id);
    }),
    mapToVoid(),
  );
};
