import { Injectable } from '@angular/core';
import { LocalizedString } from '@examdojo/core/i18n';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { rethrowError } from '@examdojo/rxjs';
import { map, Observable, tap } from 'rxjs';
import { SyllabusHttpService } from './syllabus-http.service';
import { SyllabusHttpModel, SyllabusStoreModel } from './syllabus.model';
import { SyllabusQuery } from './syllabus.query';
import { SyllabusStore } from './syllabus.store';

@Injectable({
  providedIn: 'root',
})
export class SyllabusService {
  constructor(
    private readonly store: SyllabusStore,
    private readonly query: SyllabusQuery,
    private readonly syllabusHttpService: SyllabusHttpService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  fetchSyllabuses(): Observable<SyllabusStoreModel[]> {
    return this.syllabusHttpService.fetchAll().pipe(
      map((data) => data.map((item) => this.mapHttpModelToStoreModel(item))),
      tap((storeModels) => this.store.upsertMany(storeModels)),
      this.errorHandlerService.catchError('[CategoryService]: Fetching syllabuses failed', rethrowError(), {
        toast: 'An error occurred while fetching the syllabuses',
      }),
    );
  }

  setActive(id: SyllabusStoreModel['id']) {
    this.store.setActive(id);
  }

  private mapHttpModelToStoreModel(httpModel: SyllabusHttpModel): SyllabusStoreModel {
    return {
      ...httpModel,
      name: httpModel.name as LocalizedString,
    };
  }
}
