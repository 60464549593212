import { Injectable } from '@angular/core';
import { SupabaseBaseHttpService } from '@examdojo/supabase';
import { from } from 'rxjs';
import { TopicLevel2StoreModel } from './topic-level-2.model';

@Injectable({
  providedIn: 'root',
})
export class TopicLevel2HttpService extends SupabaseBaseHttpService<'topics_level_02', 'categories'> {
  readonly schema = 'categories';
  readonly tableName = 'topics_level_02';

  fetchByIds(ids: Array<TopicLevel2StoreModel['id']>) {
    return from(
      this.supabase.client
        .schema(this.schema)
        .from(this.tableName)
        .select()
        .in('id', ids)
        .throwOnError()
        .then((response) => response.data!),
    );
  }
}
