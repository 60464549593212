import { ChangeDetectionStrategy, Component } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { TopicLevel1Query, TopicLevel2Query } from '@examdojo/category/v2';
import { LocalizedStringComponent } from '@examdojo/core/i18n';
import { IconComponent } from '@examdojo/core/icon';

@Component({
  selector: 'dojo-topic-breadcrumbs',
  standalone: true,
  imports: [IconComponent, LocalizedStringComponent],
  template: `
    @if (state.topicLevel1) {
      <div class="flex items-center gap-2 text-sm text-neutral-700">
        <div><dojo-localized-string [content]="state.topicLevel1.name" /></div>
        @if (state.topicLevel2) {
          <y42-icon icon="chevron-right" />
          <div><dojo-localized-string [content]="state.topicLevel2.name" /></div>
        }
      </div>
    }
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopicBreadcrumbsComponent {
  constructor(
    private readonly topicLevel1Query: TopicLevel1Query,
    private readonly topicLevel2Query: TopicLevel2Query,
  ) {}

  readonly state = connectState({
    topicLevel1: this.topicLevel1Query.active$,
    topicLevel2: this.topicLevel2Query.active$,
  });
}
