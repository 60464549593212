import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ExamdojoAuthService } from '@examdojo/auth';
import { EMPTY } from 'rxjs';
import { BillingService } from './billing.service';

export const billingInfoResolver: ResolveFn<void> = () => {
  const billingService = inject(BillingService);
  const authService = inject(ExamdojoAuthService);

  return authService.currentUser?.id ? billingService.fetchActiveSubscription(authService.currentUser?.id) : EMPTY;
};
