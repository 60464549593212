import { Injectable } from '@angular/core';
import { EntityState, EntityStore } from '@examdojo/state';
import { TopicLevel1StoreModel } from './topic-level-1.model';

export type TopicLevel1State = EntityState<TopicLevel1StoreModel, object, number>;

@Injectable({ providedIn: 'root' })
export class TopicLevel1Store extends EntityStore<TopicLevel1State, TopicLevel1StoreModel, 'id', number> {
  constructor() {
    super({
      name: 'topics-level-1',
      idKey: 'id',
    });
  }
}
