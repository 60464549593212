import { Injectable } from '@angular/core';
import { CompositeType, SupabaseBaseHttpService } from '@examdojo/supabase';
import { from } from 'rxjs';
import { TopicLevel2HttpModel } from '../topic-level-2';

@Injectable({
  providedIn: 'root',
})
export class PageVersionHttpService extends SupabaseBaseHttpService<'page_versions', 'learn_hub'> {
  readonly schema = 'learn_hub';
  readonly tableName = 'page_versions';

  fetchForTopicLevel2(topicLevel2Id: TopicLevel2HttpModel['id']) {
    return from(
      this.supabase.client
        .schema(this.schema)
        .from(this.tableName)
        .select()
        .eq('topic_level_02_id', topicLevel2Id)
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  publishVersion(args: CompositeType<'publish_args', 'learn_hub'>) {
    return from(
      this.supabase.client
        .schema('learn_hub')
        .rpc('publish_version', { args })
        .select()
        .throwOnError()
        .then((res) => res.data),
    );
  }

  createDraft(args: CompositeType<'create_draft_args', 'learn_hub'>) {
    return from(
      this.supabase.client
        .schema('learn_hub')
        .rpc('create_draft', { args })
        .select()
        .throwOnError()
        .then((res) => res.data),
    );
  }
}
