import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { PageVersionService, TopicLevel2Query, TopicLevel2UIModel } from '@examdojo/category/v2';
import { LocalizedStringComponent } from '@examdojo/core/i18n';
import { TranslocoPipe } from '@jsverse/transloco';
import { distinctUntilKeyChanged, filter, Observable, switchMap } from 'rxjs';
import { TopicLevel2Tab } from '../learn-hub.routes';
import { TopicBreadcrumbsComponent } from './topic-breadcrumbs.component';

@Component({
  selector: 'dojo-topic-level-2-detail-view',
  standalone: true,
  imports: [
    TranslocoPipe,
    MatTabNav,
    MatTabLink,
    MatTabNavPanel,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    TopicBreadcrumbsComponent,
    LocalizedStringComponent,
  ],
  templateUrl: './topic-level-2-detail-view.component.html',
  styleUrl: './topic-level-2-detail-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'w-full max-w-[1200px]' },
})
export class TopicLevel2DetailViewComponent {
  constructor(
    private readonly topicLevel2Query: TopicLevel2Query,
    private readonly pageVersionService: PageVersionService,
  ) {
    this.fetchPageVersionsOnTopicLevel2Change().pipe(takeUntilDestroyed()).subscribe();
  }

  readonly tabs = Object.values(TopicLevel2Tab);

  private readonly topic$: Observable<TopicLevel2UIModel | null> = this.topicLevel2Query.active$;

  readonly state = connectState({
    topic: this.topic$,
  });

  private fetchPageVersionsOnTopicLevel2Change() {
    return this.topic$.pipe(
      filter(Boolean),
      distinctUntilKeyChanged('id'),
      switchMap((topicLevel2) => this.pageVersionService.fetchForTopicLevel2(topicLevel2.id)),
    );
  }
}
