import { User as SupabaseUser } from '@supabase/supabase-js';

export type AuthUser = SupabaseUser;

export enum AuthUrlParts {
  Login = 'login',
  Register = 'register',
  Otp = 'otp',
}

export enum OidcProvider {
  Google = 'google',
  Apple = 'apple',
}
