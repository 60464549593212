import { LocalizedString } from '@examdojo/core/i18n';
import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';
import { TopicLevel1UIModel } from '../topic-level-1';

export type TopicLevel2HttpModel = Pick<
  TableRowModel<'topics_level_02', 'categories'>,
  | 'code'
  | 'course_level'
  | 'created_at'
  | 'description'
  | 'example'
  | 'id'
  | 'name'
  | 'sort_order'
  | 'topic_group_id'
  | 'topic_level_01_id'
  | 'updated_at'
>;

export type TopicLevel2StoreModel = Omit<TopicLevel2HttpModel, 'name' | 'description' | 'example'> & {
  name: LocalizedString;
  description: LocalizedString;
  example: LocalizedString;
  slug: string;
};

export type TopicLevel2UIModel = TopicLevel2StoreModel & {
  topicLevel1: TopicLevel1UIModel;
  codeNumber: string;
};

export const TOPIC_LEVEL_2_CREATE_ALLOWED_KEYS = [
  'code',
  'course_level',
  'description',
  'example',
  'name',
  'sort_order',
  'topic_group_id',
  'topic_level_01_id',
] satisfies Array<keyof TableInsertModel<'topics_level_02', 'categories'>>;

export type TopicLevel2CreateModel = Pick<
  TableInsertModel<'topics_level_02', 'categories'>,
  (typeof TOPIC_LEVEL_2_CREATE_ALLOWED_KEYS)[number]
>;

export const TOPIC_LEVEL_2_UPDATE_ALLOWED_KEYS = [...TOPIC_LEVEL_2_CREATE_ALLOWED_KEYS] satisfies Array<
  keyof TableUpdateModel<'topics_level_02', 'categories'>
>;

export type TopicLevel2UpdateModel = Pick<
  TableUpdateModel<'topics_level_02', 'categories'>,
  (typeof TOPIC_LEVEL_2_UPDATE_ALLOWED_KEYS)[number]
>;
