import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { MtxPopoverModule, MtxPopoverPosition } from '@ng-matero/extensions/popover';
import type { MtxPopoverTriggerEvent } from '@ng-matero/extensions/popover/popover-types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { connectState, RxInput, RxOnInit } from '@examdojo/angular/util';
import { KeyboardShortcutSequenceComponent, KeyboardShortcutsService } from '@examdojo/keyboard-shortcuts';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';

type KeyboardShortcutIdOrSequence = { id: string } | { sequence: string | string[] };

@RxOnInit()
@UntilDestroy()
@Component({
  selector: 'y42-keyboard-shortcut-description-tooltip',
  standalone: true,
  imports: [KeyboardShortcutSequenceComponent, MtxPopoverModule, NgTemplateOutlet],
  templateUrl: './keyboard-shortcut-description-tooltip.component.html',
  styleUrls: ['./keyboard-shortcut-description-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyboardShortcutDescriptionTooltipComponent {
  constructor(private readonly keyboardShortcutsService: KeyboardShortcutsService) {}

  @Input({ required: true })
  @RxInput()
  keyboardShortcut!: KeyboardShortcutIdOrSequence | undefined;
  private readonly keyboardShortcut$!: Observable<KeyboardShortcutIdOrSequence | undefined>;

  @Input() title?: string;
  @Input() description?: string;
  @Input() extraContent?: TemplateRef<unknown>;

  @Input() triggerOn: MtxPopoverTriggerEvent = 'hover';
  @Input() position: MtxPopoverPosition = ['below', 'center'];

  @Input()
  @RxInput()
  popoverClass?: string;
  private readonly popoverClass$!: Observable<string | undefined>;

  readonly defaultPopoverClasses = 'custom-mat-tooltip';

  private readonly keySequence$ = this.keyboardShortcut$.pipe(
    switchMap((idOrSequence) => {
      if (!idOrSequence) {
        return of(undefined);
      }
      const idCasted = (idOrSequence as { id: string }).id ?? undefined;
      const sequenceCasted = (idOrSequence as { sequence: string | string[] }).sequence ?? undefined;
      if (idCasted) {
        return this.keyboardShortcutsService
          .selectRegisteredKeySequence(idCasted)
          .pipe(map((keySequence) => keySequence?.sequence));
      } else if (sequenceCasted) {
        return of(sequenceCasted);
      }

      return of(undefined);
    }),
  );

  readonly state = connectState({
    keySequence: this.keySequence$,
    popoverClass: combineLatest([this.popoverClass$, this.keySequence$]).pipe(
      map(([classes, keySequence]) => {
        if (!keySequence) {
          return 'hidden';
        }
        return `${this.defaultPopoverClasses} ${classes}`;
      }),
    ),
  });
}
