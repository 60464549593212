import { Injectable } from '@angular/core';
import { LocalizedString } from '@examdojo/core/i18n';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { rethrowError } from '@examdojo/rxjs';
import { TableUpdateModel } from '@examdojo/supabase';
import { sanitizeObject } from '@examdojo/util/sanitize-object';
import kebabCase from 'lodash/kebabCase';
import { tap } from 'rxjs';
import { TopicLevel2HttpService } from './topic-level-2-http.service';
import {
  TOPIC_LEVEL_2_UPDATE_ALLOWED_KEYS,
  TopicLevel2HttpModel,
  TopicLevel2StoreModel,
  TopicLevel2UpdateModel,
} from './topic-level-2.model';
import { TopicLevel2Query } from './topic-level-2.query';
import { TopicLevel2Store } from './topic-level-2.store';

@Injectable({
  providedIn: 'root',
})
export class TopicLevel2Service {
  constructor(
    private readonly store: TopicLevel2Store,
    private readonly query: TopicLevel2Query,
    private readonly httpService: TopicLevel2HttpService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  fetchByIds(ids: Array<TopicLevel2StoreModel['id']>) {
    return this.httpService.fetchByIds(ids).pipe(
      tap((data) => {
        this.store.set(data.map((item) => this.mapHttpModelToStoreModel(item)));
      }),
      this.errorHandlerService.catchError('[TopicLevel2Service]: Fetching the topics failed', rethrowError(), {
        toast: 'An error occurred while fetching the topics',
      }),
    );
  }

  update(id: TopicLevel2StoreModel['id'], model: TopicLevel2UpdateModel) {
    const httpModel = this.mapUpdateModelToHttpUpdateModel(model);

    return this.httpService.update(id, httpModel).pipe(
      tap((data) => {
        this.store.upsert(id, this.mapHttpModelToStoreModel(data));
      }),
      this.errorHandlerService.catchError(
        '[ReferenceQuestionHttpService]: Updating the syllabus topic failed',
        rethrowError(),
        {
          toast: (err) => ({
            title: 'An error occurred while updating the syllabus topic',
            description: (err as Error)?.message,
          }),
        },
      ),
    );
  }

  setActiveBySlug(slug: TopicLevel2StoreModel['slug']) {
    const topic = this.query.getAllUIEntities().find((t) => t.slug === slug);
    if (!topic) {
      this.errorHandlerService.error(`Topic level 2 with slug ${slug} not found`);
      return;
    }
    return this.store.setActive(topic.id);
  }

  reset() {
    this.store.reset();
  }

  private mapUpdateModelToHttpUpdateModel(
    updateModel: TopicLevel2UpdateModel,
  ): TableUpdateModel<'topics_level_02', 'categories'> {
    return sanitizeObject(updateModel, TOPIC_LEVEL_2_UPDATE_ALLOWED_KEYS);
  }

  private mapHttpModelToStoreModel(httpModel: TopicLevel2HttpModel): TopicLevel2StoreModel {
    const name = httpModel.name as LocalizedString;
    return {
      ...httpModel,
      name,
      description: httpModel.description as LocalizedString,
      example: httpModel.example as LocalizedString,
      slug: kebabCase(`${httpModel.code} ${name.en}`),
    };
  }
}
