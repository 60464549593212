import { Injectable } from '@angular/core';
import { EntityState, EntityStore } from '@examdojo/state';
import { TopicLevel2StoreModel } from './topic-level-2.model';

export type TopicLevel2State = EntityState<TopicLevel2StoreModel, object, number>;

@Injectable({ providedIn: 'root' })
export class TopicLevel2Store extends EntityStore<TopicLevel2State, TopicLevel2StoreModel, 'id', number> {
  constructor() {
    super({
      name: 'topics-level-2',
      idKey: 'id',
    });
  }
}
