import { Injectable } from '@angular/core';
import { QueryEntity } from '@examdojo/state';
import { SyllabusStoreModel, SyllabusUIModel } from './syllabus.model';
import { SyllabusState, SyllabusStore } from './syllabus.store';

@Injectable({ providedIn: 'root' })
export class SyllabusQuery extends QueryEntity<SyllabusState, SyllabusStoreModel, SyllabusUIModel, 'id', number> {
  constructor(protected override store: SyllabusStore) {
    super(store);
  }
}
