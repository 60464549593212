import { Route } from '@angular/router';
import { getActiveTopicsResolver } from '@examdojo/category/v2';
import { RootUrlParts } from '../../app.model';
import { TopicTabComponent } from './topic-level-2-detail-view/tabs/topic-tab/topic-tab.component';
import { TopicLevel2DetailViewComponent } from './topic-level-2-detail-view/topic-level-2-detail-view.component';

export const TOPIC_LEVEL_1_SLUG_PARAM = 'topicLevel1Slug';
export const TOPIC_LEVEL_2_SLUG_PARAM = 'topicLevel2Slug';

export enum TopicLevel2Tab {
  Concept = 'concept',
  CheatSheet = 'cheat_sheet',
  Insights = 'insights',
  PastPapers = 'past_papers',
}

export const LEARN_HUB_ROUTES: Route[] = [
  {
    path: RootUrlParts.LearnHub,
    loadComponent: () => import('./learn-hub.component').then((m) => m.LearnHubComponent),
    children: [
      {
        path: `:${TOPIC_LEVEL_1_SLUG_PARAM}/:${TOPIC_LEVEL_2_SLUG_PARAM}`,
        component: TopicLevel2DetailViewComponent,
        resolve: {
          activeTopics: getActiveTopicsResolver({
            topicLevel1SlugParam: TOPIC_LEVEL_1_SLUG_PARAM,
            topicLevel2SlugParam: TOPIC_LEVEL_2_SLUG_PARAM,
          }),
        },
        children: [
          { path: '', redirectTo: TopicLevel2Tab.Concept, pathMatch: 'full' },
          {
            path: TopicLevel2Tab.Concept,
            component: TopicTabComponent,
            data: { pageType: 'concept' },
          },
          { path: TopicLevel2Tab.CheatSheet, component: TopicTabComponent, data: { pageType: 'cheat_sheet' } },
          { path: TopicLevel2Tab.Insights, component: TopicTabComponent, data: { pageType: 'insights' } },
          { path: TopicLevel2Tab.PastPapers, component: TopicTabComponent, data: { pageType: 'past_papers' } },
        ],
      },
    ],
  },
];
