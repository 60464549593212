<tiptap-editor [editor]="editor" [formControl]="formCtrl()" [class.editable]="editable()"></tiptap-editor>

<tiptap-bubble-menu [editor]="editor">
  <div class="flex items-center gap-1 divide-x rounded border border-black bg-black p-1 text-gray-300">
    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().toggleBold().run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('bold') }"
    >
      <y42-icon icon="bold" />
    </button>
    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().toggleItalic().run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('italic') }"
    >
      <y42-icon icon="italic" />
    </button>
    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().toggleStrike().run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('strike') }"
    >
      <y42-icon icon="strikethrough" />
    </button>

    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().setTextAlign('left').run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive({ textAlign: 'left' }) }"
    >
      <y42-icon icon="align-left" />
    </button>
    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().setTextAlign('center').run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive({ textAlign: 'center' }) }"
    >
      <y42-icon icon="align-center" />
    </button>
    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().setTextAlign('right').run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive({ textAlign: 'right' }) }"
    >
      <y42-icon icon="align-right" />
    </button>

    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().toggleBulletList().run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('strike') }"
    >
      <y42-icon icon="list-ul" />
    </button>

    <button
      type="button"
      class="px-1 hover:text-white"
      (click)="editor.chain().focus().toggleOrderedList().run()"
      [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('strike') }"
    >
      <y42-icon icon="list-ol" />
    </button>
  </div>
</tiptap-bubble-menu>
