/* eslint-disable @typescript-eslint/no-restricted-imports */

import { Injectable, Optional } from '@angular/core';
import { IS_PROD_BUILD, UserEnvironmentService } from '@examdojo/core/environment';
import {
  Duration,
  Locale,
  add,
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  endOfDay,
  endOfHour,
  endOfMinute,
  endOfMonth,
  endOfSecond,
  endOfWeek,
  endOfYear,
  format,
  formatDistanceToNow,
  formatDuration,
  intervalToDuration,
  isAfter,
  isBefore,
  isSameDay,
  isSameHour,
  isSameMinute,
  isSameMonth,
  isSameSecond,
  isSameWeek,
  isSameYear,
  parse,
  parseISO,
  set,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfMonth,
  startOfSecond,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns';
import { DATE_TIME_FORMATS, DateTimeFormat, Datestring, ExtendedUnitOfTime, UnitOfTime } from './date-time.model';
import { timeToDuration } from './time-to-duration';

@Injectable({ providedIn: 'root' })
export class DateTimeService {
  constructor(@Optional() private readonly userEnvironmentService: UserEnvironmentService | null) {}

  private readonly isProd = IS_PROD_BUILD;
  private readonly formatDistanceLocale = {
    xSeconds: '{{count}}s',
    xMinutes: '{{count}}m',
    xHours: '{{count}}h',
    xDays: '{{count}}d',
    xWeeks: '{{count}}w',
    xMonths: '{{count}}mo',
    xYears: '{{count}}y',
  };
  private readonly shortEnLocale: Locale = {
    formatDistance: (token: keyof typeof this.formatDistanceLocale, count) =>
      this.formatDistanceLocale[token].replace('{{count}}', count),
  };

  get locale(): string {
    return this.userEnvironmentService?.locale || 'en';
  }

  get browserTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  get userTimeZone() {
    return this.userEnvironmentService?.timezone || this.browserTimeZone;
  }

  get userToBrowserTimeZoneDiff() {
    const now = new Date();
    const commonLocale = 'en-US';
    const userTimeZoneNow = now.toLocaleString(commonLocale, { timeZone: this.userTimeZone });
    const browserTimeZoneNow = now.toLocaleString(commonLocale, { timeZone: this.browserTimeZone });

    const timeZoneDiffInMs = new Date(userTimeZoneNow).getTime() - new Date(browserTimeZoneNow).getTime();
    const timeZoneDiffInMinutes = timeZoneDiffInMs / (60 * 1000);
    const timeZoneDiffInHours = timeZoneDiffInMinutes / 60;

    return {
      millisecondsDiff: timeZoneDiffInMs,
      minutesDiff: timeZoneDiffInMinutes,
      hoursDiff: timeZoneDiffInHours,
    };
  }

  parseDate(dateString: Datestring | number, formatString?: string): Date {
    if (!dateString) {
      if (!this.isProd) {
        console.warn(`[DateTime]: trying to parse undefined date`, dateString);
      }
      return new Date(0);
    }

    return typeof dateString === 'string'
      ? formatString
        ? parse(dateString, formatString, new Date())
        : parseISO(dateString)
      : new Date(dateString);
  }

  /**
   * Use me only in special cases.
   * @param date
   * @param format
   * @returns
   */
  __format(date: Date, formatString: string, adjustForUTCOffset = false): string {
    let transformedDate = date;
    if (adjustForUTCOffset) {
      transformedDate = this.__adjustForUTCOffset(date);
    }

    try {
      return format(transformedDate, formatString);
    } catch (err) {
      console.warn(`[DateTimeService]: error formatting invalid value "${date.toString()}"`);
      throw err;
    }
  }

  formatDate(
    date: Datestring | number | undefined,
    formatString: DateTimeFormat,
    options?: { originalFormat: string },
  ): string {
    if (!date) {
      return '';
    }

    const dateTimeFormat = DATE_TIME_FORMATS[formatString];
    const timeZone = this.userTimeZone;
    const parsedDate = this.parseDate(date, options?.originalFormat);

    try {
      return Intl.DateTimeFormat(this.locale, { ...dateTimeFormat, timeZone }).format(parsedDate);
    } catch (err) {
      console.warn(`[DateTimeService]: error formatting invalid value "${date.toString()}"`);
      throw err;
    }
  }

  formatDateRelativeToNow(date: Datestring, addSuffix = true, locale?: Locale): string {
    return formatDistanceToNow(this.parseDate(date), {
      addSuffix,
      locale,
      // setting the locale on this function translates the returned string into the locale's language
      // which we don't want as long as we don't support translating our application
      // locale: getDateFnsLocale(this.locale),
    });
  }

  /**
   * Formats a duration to a short string, e.g. "15m ago" or "1h ago"
   */
  formatDateRelativeToNowShort(date: Datestring, addSuffix = true): string {
    const formatDistanceLocale = {
      lessThanXSeconds: '{{count}}s',
      xSeconds: '{{count}}s',
      halfAMinute: '30s',
      lessThanXMinutes: '{{count}}m',
      xMinutes: '{{count}}m',
      aboutXHours: '{{count}}h',
      xHours: '{{count}}h',
      xDays: '{{count}}d',
      aboutXWeeks: '{{count}}w',
      xWeeks: '{{count}}w',
      aboutXMonths: '{{count}}mo',
      xMonths: '{{count}}mo',
      aboutXYears: '{{count}}y',
      xYears: '{{count}}y',
      overXYears: '{{count}}y',
      almostXYears: '{{count}}y',
    };

    const locale: Locale = {
      formatDistance: (token: keyof typeof formatDistanceLocale, count, options) => {
        options = options || {};

        const result = formatDistanceLocale[token]?.replace('{{count}}', count);

        if (options.addSuffix) {
          if (options.comparison > 0) {
            return `in ${result}`;
          } else {
            return `${result} ago`;
          }
        }

        return result;
      },
    };

    return formatDistanceToNow(this.parseDate(date), {
      addSuffix,
      locale,
    });
  }

  formatDuration(amountOfTime: Duration | number, options?: FormatDurationOptions): string {
    const duration: Duration =
      typeof amountOfTime === 'object' ? amountOfTime : timeToDuration(amountOfTime, options?.unit);

    const formattedDuration = formatDuration(duration, {
      // setting the locale on this function translates the returned string into the locale's language
      // which we don't want as long as we don't support translating our application
      // locale: getDateFnsLocale(this.locale),
    });

    if (options?.granularity) {
      return formattedDuration
        .split(' ')
        .slice(0, options.granularity * 2)
        .join(' ');
    }

    return formattedDuration;
  }

  /**
   * Formats a duration to a short string, e.g. "1h 30m" or "1h"
   */
  formatDurationShort(amountOfTime: Duration | number, options?: FormatDurationOptions): string {
    const duration: Duration =
      typeof amountOfTime === 'object' ? amountOfTime : timeToDuration(amountOfTime, options?.unit);

    const formattedDuration = formatDuration(duration, {
      locale: this.shortEnLocale,
    });

    if (options?.granularity) {
      return formattedDuration.split(' ').slice(0, options.granularity).join(' ');
    }

    return formattedDuration;
  }

  getDurationBetweenDates(start: Datestring, end: Datestring): Duration {
    return intervalToDuration({
      start: this.parseDate(start),
      end: this.parseDate(end),
    });
  }

  diff(
    date: Datestring | null | undefined,
    dateToCompare: Datestring | null | undefined,
    unit?: ExtendedUnitOfTime,
  ): number {
    if (!date || !dateToCompare) {
      return 0;
    }

    type DateParam = Parameters<typeof differenceInSeconds>[0];
    const unitToFn: Record<ExtendedUnitOfTime, (dateLeft: DateParam, dateRight: DateParam) => number> = {
      milliseconds: differenceInMilliseconds,
      seconds: differenceInSeconds,
      minutes: differenceInMinutes,
      hours: differenceInHours,
      days: differenceInDays,
      weeks: differenceInWeeks,
      months: differenceInMonths,
      years: differenceInYears,
    };

    const compareFn = unit && unitToFn[unit];

    if (!compareFn) {
      return this.parseDate(dateToCompare).valueOf() - this.parseDate(date).valueOf();
    }

    return compareFn(this.parseDate(dateToCompare), this.parseDate(date));
  }

  isBefore(date: Datestring, dateToCompare: Datestring, unit?: UnitOfTime): boolean {
    if (!date || !dateToCompare) {
      return false;
    }

    const unitToGranularityFn: Record<UnitOfTime, { start: (date: Date) => Date; end: (date: Date) => Date }> = {
      seconds: { start: startOfSecond, end: endOfSecond },
      minutes: { start: startOfMinute, end: endOfMinute },
      hours: { start: startOfHour, end: endOfHour },
      days: { start: startOfDay, end: endOfDay },
      weeks: { start: startOfWeek, end: endOfWeek },
      months: { start: startOfMonth, end: endOfMonth },
      years: { start: startOfYear, end: endOfYear },
    };
    const granularityFn = unit && unitToGranularityFn[unit];
    if (!granularityFn) {
      return isBefore(this.parseDate(date), this.parseDate(dateToCompare));
    }

    return isBefore(granularityFn.start(this.parseDate(date)), granularityFn.end(this.parseDate(dateToCompare)));
  }

  isAfter(date: Datestring, dateToCompare: Datestring, unit?: UnitOfTime): boolean {
    if (!date || !dateToCompare) {
      return false;
    }

    const unitToGranularityFn: Record<UnitOfTime, { start: (date: Date) => Date; end: (date: Date) => Date }> = {
      seconds: { start: startOfSecond, end: endOfSecond },
      minutes: { start: startOfMinute, end: endOfMinute },
      hours: { start: startOfHour, end: endOfHour },
      days: { start: startOfDay, end: endOfDay },
      weeks: { start: startOfWeek, end: endOfWeek },
      months: { start: startOfMonth, end: endOfMonth },
      years: { start: startOfYear, end: endOfYear },
    };
    const granularityFn = unit && unitToGranularityFn[unit];
    if (!granularityFn) {
      return isAfter(this.parseDate(date), this.parseDate(dateToCompare));
    }

    return isAfter(granularityFn.start(this.parseDate(date)), granularityFn.end(this.parseDate(dateToCompare)));
  }

  isSame(date: Datestring | undefined, dateToCompare: Datestring | undefined, unit?: UnitOfTime): boolean {
    if (!date || !dateToCompare) {
      return false;
    }

    type DateParam = Parameters<typeof isSameMinute>[0];
    const unitToFn: Record<UnitOfTime, (dateLeft: DateParam, dateRight: DateParam) => boolean> = {
      seconds: isSameSecond,
      minutes: isSameMinute,
      hours: isSameHour,
      days: isSameDay,
      weeks: isSameWeek,
      months: isSameMonth,
      years: isSameYear,
    };

    const compareFn = unit && unitToFn[unit];

    if (!compareFn) {
      return this.parseDate(date).valueOf() === this.parseDate(dateToCompare).valueOf();
    }

    return compareFn(this.parseDate(date), this.parseDate(dateToCompare));
  }

  isBetween(
    date: Datestring,
    startDateToCompare: Datestring,
    endDateToCompare: Datestring,
    unit?: UnitOfTime,
  ): boolean {
    return this.isAfter(date, startDateToCompare, unit) && this.isBefore(date, endDateToCompare, unit);
  }

  add(date: Datestring, duration: Duration) {
    return add(this.parseDate(date), duration);
  }

  subtract(date: Datestring, duration: Duration) {
    return sub(this.parseDate(date), duration);
  }

  startOfDay(date: Datestring): Date {
    return startOfDay(this.parseDate(date));
  }

  endOfDay(date: Datestring): Date {
    return endOfDay(this.parseDate(date));
  }

  startOfMonth(date: Datestring): Date {
    return startOfMonth(this.parseDate(date));
  }

  endOfMonth(date: Datestring): Date {
    return endOfMonth(this.parseDate(date));
  }

  set(date: Datestring, values: Parameters<typeof set>[1]): Date {
    return set(this.parseDate(date), values);
  }

  getStartOfWeek(date: Datestring, options?: { weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 }): Date {
    return startOfWeek(this.parseDate(date), options);
  }

  /**
   * Used in ag-grid to compare dates
   */
  filterDateInComparator(filterLocalDateAtMidnight: Date, cellValue: string | null) {
    if (cellValue === null) {
      return null;
    }

    const cellDate = new Date(cellValue);

    cellDate.setHours(0, 0, 0, 0);

    return cellDate.getTime() - filterLocalDateAtMidnight.getTime();
  }

  /**
   * Temporary...
   * @param date
   * @returns
   */
  private __adjustForUTCOffset(date: Date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );
  }
}

interface FormatDurationOptions {
  /** The unit of time to format the duration in.*/
  unit?: ExtendedUnitOfTime;

  /**
   * Determines the number of units we want to show for the duration
   * Examples for 1: "3mo", "1y", "6d"
   * Examples for 2: "3mo 17d", "1y 8mo", "6d 14h"
   */
  granularity?: number;
}
