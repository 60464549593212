import { Component, computed, input } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'dojo-markdown-viewer',
  standalone: true,
  template: ` <markdown [data]="contentWithFixedContent()" katex ngPreserveWhitespaces /> `,
  styles: `
    :host ::ng-deep markdown {
      h1, h2 {
        font-size: theme('fontSize.display-xs.0');
        line-height: theme('fontSize.display-xs.1');
      }

      h3, h4, h5 {
        font-size: theme('fontSize.md.0');
        line-height: theme('fontSize.md.1');;
      }

      h6 {
        font-size: theme('fontSize.sm.0');
        line-height: theme('fontSize.sm.1');;
      }

      h1, h2, h3, h4, h5, h6 {
        font-weight: theme('fontWeight.medium-exam');
        margin-bottom: theme('spacing.3');
      }

      p {
        margin-bottom: theme('spacing.3');
      }

      p:only-child {
        margin: 0;
      }

      ol, ul {
        padding-left: theme('spacing.7');
        margin-bottom: theme('spacing.3');
        list-style: auto;
      }

      b, strong {
        font-weight: 600;
      }

      /*render specific UI for different types of markdown blocks*/
      .example-block {
        border-radius: 1rem;
        padding: theme('spacing.4');
        border: 1px solid;
        font-size: theme('fontSize.sm.0');
        line-height: theme('fontSize.sm.1');
        margin-bottom: theme('spacing.3');

        > div {
          &::before {
            display: flex;
            --icon-size: theme('spacing.4');
            padding-left: calc(#{var(--icon-size)} + theme('spacing.2'));
            background-size: var(--icon-size) var(--icon-size);
            background-repeat: no-repeat;
            background-position: left center;
            margin-bottom: theme('spacing.3');
            text-transform: uppercase;
            font-weight: 500;
            font-size: theme('fontSize.xs.0');
            line-height: theme('fontSize.xs.1');
          }
        }

        &.warning {
          border-color: theme('colors.yellow.200');
          background: theme('colors.yellow.50');
          color: theme('colors.yellow.700');

          > div {
            &::before {
              content: 'Warning';
              background-image: url('/assets/images/callout/warning-icon.svg');
            }
          }
        }

        &.note {
          border-color: theme('colors.blue.200');
          background: theme('colors.blue.50');
          color: theme('colors.blue.700');

          > div {
            &::before {
              content: 'Note';
              background-image: url('/assets/images/callout/note-icon.svg');
            }
          }
        }

        &.tip {
          border-color: theme('colors.violet.200');
          background: theme('colors.violet.50');
          color: theme('colors.violet.700');

          > div {
            &::before {
              content: 'Tip';
              background-image: url('/assets/images/callout/tip-icon.svg');
            }
          }
        }

        &.example {
          border-color: theme('colors.green.200');
          background: theme('colors.green.50');
          color: theme('colors.green.700');

          > div {
            &::before {
              content: 'Example';
              background-image: url('/assets/images/callout/example-icon.svg');
            }
          }
        }
      }
    }
  `,
  imports: [MarkdownModule],
  host: { class: 'max-w-full' },
})
export class MarkdownViewerComponent {
  readonly content = input.required<string>();
  readonly matrixRegexp = /(\\begin\{p?matrix\}[\s\S]*?\\end\{p?matrix\})/g;

  readonly contentWithFixedContent = computed(() => {
    const content = this.content();

    // https://linear.app/tm007/issue/FE-504
    const fixedLatexContent = content.replace(/\$(.*?)\$/gs, (match, p1) => `$${p1.replaceAll('\\%', '\\\\%')}$`);

    // https://linear.app/tm007/issue/FE-381
    return fixedLatexContent.replaceAll(
      this.matrixRegexp,
      // Replace 2 backslashes with 4 backslashes for the matrix line separation.
      (match, p1) => `${p1.replaceAll('\\\\', '\\\\\\\\')}`,
    );
  });
}
