import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { EMAIL_CHANGE_SUCCESS_QUERY_PARAM } from '@examdojo/auth';
import { ToastService } from '@examdojo/toast';
import { TranslocoService } from '@jsverse/transloco';
import { distinctUntilChanged, filter, first, map, switchMap, take, tap } from 'rxjs';

@Injectable()
export class HandleEmailVerifiedService {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {
    this.isEmailVerifiedQueryParamPresent$
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.translocoService.selectTranslate('email_verified').pipe(first())),
        tap((message) => {
          this.toastService.success(message);
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  readonly isEmailVerifiedQueryParamPresent$ = this.route.queryParams.pipe(
    map((params) => !!params[EMAIL_CHANGE_SUCCESS_QUERY_PARAM]),
    distinctUntilChanged(),
  );
}
