import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { mapToVoid } from '@examdojo/rxjs';
import { catchError, EMPTY, first } from 'rxjs';
import { CategoryService } from './category.service';

export const categoriesResolver: ResolveFn<void> = () => {
  const categoryService = inject(CategoryService);
  const errorHandler = inject(ErrorHandlerService);

  return categoryService.fetchAll().pipe(
    errorHandler.catchHttpErrors(() => EMPTY),
    catchError(() => EMPTY),
    first(),
    mapToVoid(),
  );
};
