import { ScrollingModule } from '@angular/cdk/scrolling';
import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { InputObservable, connectState } from '@examdojo/angular/util';
import { ButtonComponent } from '@examdojo/core/button';
import { IconButtonComponent } from '@examdojo/core/icon-button';
import { KeyboardShortcutComponent, KeyboardShortcutDescriptionTooltipComponent } from '@examdojo/keyboard-shortcuts';
import { PlatformService } from '@examdojo/platform';
import { areArraysEqual } from '@examdojo/util/are-arrays-equal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import uniq from 'lodash/uniq';
import { BehaviorSubject, Observable, combineLatest, filter, fromEvent, map, merge, tap, withLatestFrom } from 'rxjs';
import { DialogSize } from './mat-dialog.model';

/**
 * @deprecated Use DialogService instead.
 */
@UntilDestroy()
@Component({
  selector: 'y42-mat-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    ButtonComponent,
    IconButtonComponent,
    KeyboardShortcutComponent,
    ScrollingModule,
    MatTooltipModule,
    KeyboardShortcutDescriptionTooltipComponent,
    NgIf,
    NgClass,
  ],
  templateUrl: './mat-dialog.component.html',
  styleUrls: ['./mat-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatDialogComponent<R = unknown> {
  constructor(protected readonly sanitizer: DomSanitizer) {
    merge(this.confirmOnCmdEnter(), this.toggleFullscreenOnCmdShiftArrow()).pipe(untilDestroyed(this)).subscribe();
  }

  private readonly platform = inject(PlatformService);

  protected readonly dialogRef: MatDialogRef<MatDialogComponent<unknown>, R | undefined | void> = inject(MatDialogRef);
  private readonly document = inject(DOCUMENT);

  private readonly matDialog = inject(MatDialog);
  private readonly initiallyOpenedDialogs = this.getCurrentlyOpenedDialogsIds();

  @Input({ required: true })
  @InputObservable()
  dialogTitle = '';
  private readonly dialogTitle$!: Observable<string | undefined>;

  @HostBinding('class.scrollable-body')
  @Input()
  scrollableBody = false;

  @Input()
  noBodyPadding = false;

  /**
   * The dialog's width. The default value is `medium`.
   */
  @Input()
  @InputObservable()
  width: DialogSize = 'medium';
  private readonly width$!: Observable<DialogSize | undefined>;

  @HostBinding(`attr.data-width`)
  get widthAttr() {
    return this.state.width;
  }

  /**
   * The dialog's height. The default value is `auto`.
   */
  @Input()
  @InputObservable()
  height: DialogSize = 'auto';
  private readonly height$!: Observable<DialogSize | undefined>;

  @HostBinding(`attr.data-height`)
  get heightAttr() {
    return this.state.height;
  }

  /**
   * When set to `false` the dialog will not have close/cancel buttons.
   * Remember to also provide `disableClose: true,` in the `MatDialog` config to
   * prevent closing when clicking the dialog's backdrop.
   */
  @Input() closeable = true;

  /**
   * A callback that is executed when the dialog is closed.
   *
   * When the callback returns `false` then the dialog will not be closed.
   */
  @Input() cancelFn: (() => Promise<void | boolean> | void) | null = null;

  /**
   * Disables confirming the dialog with CMD+Enter or CTRL+Enter.
   */
  @Input()
  disableCmdEnter = false;

  /**
   * **Warning:** Works only with the default header template.
   */
  @Input() allowFullscreen = false;

  /**
   * When defined the dialog will have an additional confirmation button with given label.
   */
  @Input()
  @InputObservable()
  confirmLabel?: string | TemplateRef<unknown> | null;
  private readonly confirmLabel$!: Observable<string | TemplateRef<unknown> | null>;

  @Input() confirmColor?: ThemePalette | null = 'primary';
  @Input() confirmPending?: boolean | null = false;

  @Input()
  confirmDisabled = false;

  @Input()
  confirmDisabledTooltip = '';

  /**
   * Defaults to `'Cancel'` when `confirmLabel` is defined.
   * Defaults to `'Close'` when `confirmLabel` is not defined.
   * Hides the button when `null`.
   */
  @Input() cancelLabel?: string | null;
  @Input() cancelColor?: ThemePalette;

  @Input() hideFooter? = false;

  @Output() confirm: EventEmitter<void> = new EventEmitter();

  readonly fullscreen$$ = new BehaviorSubject<boolean>(false);

  readonly state = connectState({
    height: combineLatest([this.fullscreen$$, this.height$]).pipe(
      map(([fullscreen, height]) => (fullscreen ? 'fullscreen' : height)),
    ),
    width: combineLatest([this.fullscreen$$, this.width$]).pipe(
      map(([fullscreen, width]) => (fullscreen ? 'fullscreen' : width)),
    ),
    fullscreen: this.fullscreen$$,
    toggleFullscreenKeys: this.fullscreen$$.pipe(
      map((fullscreen) => {
        return ['command', 'shift', fullscreen ? 'down' : 'up'].join('+');
      }),
    ),
    confirmLabelTemplate: this.confirmLabel$.pipe(
      map((label) => label instanceof TemplateRef),
      map((isTemplatRef) => (isTemplatRef ? (this.confirmLabel as TemplateRef<unknown>) : null)),
    ),
    dialogTitleSanitized: this.dialogTitle$.pipe(
      map((title) => (title ? this.sanitizer.bypassSecurityTrustHtml(title) : null)),
    ),
  });

  toggleFullscreen() {
    this.fullscreen$$.next(!this.fullscreen$$.value);
  }

  async cancelClicked() {
    if (this.cancelFn) {
      const result = await this.cancelFn();

      if (result === false) {
        return;
      }
    }

    this.dialogRef.close();
  }

  confirmed() {
    this.confirm.emit();
  }

  private confirmOnCmdEnter() {
    // We need to handle the event during the capture phase
    // Otherwise a form input may capture the event first.
    // And mousetrap library (used by keyboard shortcuts) does not support capturing
    // https://github.com/ccampbell/mousetrap/pull/400.
    return fromEvent<KeyboardEvent>(this.document, 'keydown', { capture: true }).pipe(
      filter((event) => event.key === 'Enter' && this.platform.eventHasCmdOrCtrlModifier(event)),
      filter(() => this.isThisDialogInstanceOnTop()),
      tap((event) => event.stopPropagation()),
      filter(() => !this.disableCmdEnter),
      filter(() => !this.confirmDisabled && !this.confirmPending),
      tap(() => this.confirm.emit()),
    );
  }

  private toggleFullscreenOnCmdShiftArrow() {
    return fromEvent<KeyboardEvent>(this.document, 'keydown', { capture: true }).pipe(
      withLatestFrom(this.fullscreen$$),
      filter(() => this.allowFullscreen),
      filter(([event, fullscreen]) => {
        const expectedArrowKey = fullscreen ? 'ArrowDown' : 'ArrowUp';
        return event.key === expectedArrowKey && event.shiftKey && this.platform.eventHasCmdOrCtrlModifier(event);
      }),
      filter(() => this.isThisDialogInstanceOnTop()),
      tap(([event]) => event.stopPropagation()),
      tap(() => this.toggleFullscreen()),
    );
  }

  private getCurrentlyOpenedDialogsIds() {
    return uniq([...this.matDialog.openDialogs.map((matRef) => matRef.id), this.dialogRef.id]);
  }

  private isThisDialogInstanceOnTop() {
    return areArraysEqual(this.getCurrentlyOpenedDialogsIds(), this.initiallyOpenedDialogs, {
      orderMatters: false,
    });
  }
}
