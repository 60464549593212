<div class="flex w-full flex-col gap-2">
  <div class="flex flex-wrap justify-between gap-2">
    <div>
      <h3 class="font-medium-exam text-secondary mb-1 text-sm">Your current plan</h3>
      <h3 class="font-medium-exam text-display-xs text-stone-800">
        Examdojo {{ state.userHasProPlan ? 'Pro' : 'Free' }}
      </h3>
    </div>
    @if (state.userHasProPlan) {
      <dojo-button [pending]="state.customerPortalLoading" [fill]="'outline'" (click)="openCustomerPortal()">
        Manage subscription
      </dojo-button>
    } @else {
      <dojo-button (click)="openProPlanCheckout()" [pending]="state.checkoutSessionLoading"> Get Pro </dojo-button>
    }
  </div>
  @if (state.userHasProPlan) {
    <div class="mt-8">Valid until: {{ state.activeSubscription?.end_date ?? '' | formatDate }}</div>
  }
</div>
