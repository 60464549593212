import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IS_PROD_BUILD } from '@examdojo/core/environment';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { OperatingSystem, PlatformService } from '@examdojo/platform';
import { UnwrapRecordValue } from '@examdojo/core/typescript';
import { assertNonNullable } from '@examdojo/util/assert';
import { ensureArray } from '@examdojo/util/ensure-array';
import { isNotNullish } from '@examdojo/util/nullish';
import { entries } from '@examdojo/util/object-utils';
import isEqual from 'lodash/isEqual';
import Mousetrap, { ExtendedKeyboardEvent, MousetrapStatic } from 'mousetrap';
import { BehaviorSubject, filter, Observable, pairwise, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { isEditableElement, isShortcutComboInputSafe } from './keyboard-shortcut-input-safety';
import {
  AdHocInputKeySequence,
  KeyboardShortcutDefinitionSet,
  KeyboardShortcutsNamespace,
  KeySequence,
  KeySequenceStored,
  ModifierKey,
  NamedKeySequence,
  RegisteredKeyboardShortcutDefinitionSet,
} from './keyboard-shortcuts.model';
import { SUPPRESS_REGISTERED_KEYBOARD_SHORTCUTS_CLASS } from './suppress-registered-keyboard-shortcuts.directive';
import { areKeySequencesEqual } from './util/are-key-sequences-equal';

export interface KeySequenceLabel {
  base: string;
  decorator?: string;
}

export interface KeySequenceLabels extends Omit<KeySequenceStored, 'callback'> {
  labels: KeySequenceLabel[];
  rawLabel: string;
}

interface KeySequenceMap {
  [key: string]: KeySequenceLabel;
}

const COMMON_KEYS_MAP: KeySequenceMap = {
  left: { base: '', decorator: '←' },
  right: { base: '', decorator: '→' },
  up: { base: '', decorator: '↑' },
  down: { base: '', decorator: '↓' },
  shift: { base: 'shift', decorator: '⇧' },
  tab: { base: 'tab', decorator: '↹' },
  '=': { base: '+' },
  enter: { base: '', decorator: '⏎' },
};

const osToKeySequences: Partial<Record<OperatingSystem, KeySequenceMap>> = {
  [OperatingSystem.MacOS]: {
    ...COMMON_KEYS_MAP,
    [ModifierKey.Meta]: { base: 'cmd', decorator: '⌘' },
    [ModifierKey.Mod]: { base: 'cmd', decorator: '⌘' },
    [ModifierKey.Command]: { base: 'cmd', decorator: '⌘' },
    [ModifierKey.Ctrl]: { base: 'control' },
    [ModifierKey.Alt]: { base: 'option', decorator: '⌥' },
  },
  [OperatingSystem.Linux]: {
    ...COMMON_KEYS_MAP,
    [ModifierKey.Meta]: { base: 'meta' },
    [ModifierKey.Mod]: { base: 'meta' },
    [ModifierKey.Command]: { base: 'ctrl' },
    [ModifierKey.Ctrl]: { base: 'ctrl' },
    [ModifierKey.Alt]: { base: 'alt' },
  },
  [OperatingSystem.Windows]: {
    ...COMMON_KEYS_MAP,
    [ModifierKey.Meta]: { base: 'win', decorator: '⊞' },
    [ModifierKey.Mod]: { base: 'win', decorator: '⊞' },
    [ModifierKey.Command]: { base: 'ctrl' },
    [ModifierKey.Ctrl]: { base: 'ctrl' },
    [ModifierKey.Alt]: { base: 'alt', decorator: '⎇' },
  },
  [OperatingSystem.Other]: COMMON_KEYS_MAP,
};

export const AD_HOC_DEFINITION_SET_ID = 'ad-hoc';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class KeyboardShortcutsService {
  constructor(
    private readonly platform: PlatformService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {
    this.overrideStopCallback();
    this.updateBoundSequenceOnStackChange().pipe(untilDestroyed(this)).subscribe();
  }

  private readonly mousetrapInstance = new Mousetrap();

  private readonly keysMap = osToKeySequences[this.platform.operatingSystem];

  private readonly keySequenceStacks$$ = new BehaviorSubject<Record<string, KeySequenceStored[]>>({});
  private readonly keySequenceStacks$ = this.keySequenceStacks$$.asObservable();

  private readonly registeredDefinitionSets$$ = new BehaviorSubject<
    Record<string, RegisteredKeyboardShortcutDefinitionSet<string>>
  >({});
  readonly registeredDefinitionSets$ = this.registeredDefinitionSets$$.asObservable();

  /**
   * Register a keyboard shortcut that does not belong to a set
   */
  registerAdHoc(keySequence: AdHocInputKeySequence) {
    const { callback, name, description, sequence } = keySequence;
    const id = keySequence.id ?? `adhoc-${uuid()}`;
    const namespace = keySequence.namespace ?? KeyboardShortcutsNamespace.Global;

    const registeredSets = this.getAllRegisteredSets();
    const adHocSet = registeredSets[AD_HOC_DEFINITION_SET_ID] ?? {};

    if (adHocSet[id]) {
      this.errorHandlerService.log(
        `Registering an ad-hoc keyboard shortcut with an id that already exists: "${id}". Ids need to be unique for ad-hoc key sequences.`,
        {
          context: { keySequence, existingAdHocSet: adHocSet },
        },
      );
    }

    this.registerDefinitionSet(AD_HOC_DEFINITION_SET_ID, {
      ...adHocSet,
      [id]: { sequence, callback, name, description, namespace },
    });

    return id;
  }

  unregisterAdHoc(id: string) {
    const { [AD_HOC_DEFINITION_SET_ID]: adHocSet, ...otherSets } = this.getAllRegisteredSets();
    assertNonNullable(adHocSet, 'adHocSet');

    const adHocKeySequences = Object.values(adHocSet).filter(isNotNullish);

    const targetKeySequenceIndex = adHocKeySequences.findIndex((ks) => ks.id === id);
    const targetKeySequence = adHocKeySequences[targetKeySequenceIndex];

    if (!targetKeySequence) {
      return;
    }

    assertNonNullable(targetKeySequence, 'targetKeySequence');
    this.unregister(targetKeySequence.id, targetKeySequence.namespace);

    const updatedAdHocSet = [
      ...adHocKeySequences.slice(0, targetKeySequenceIndex),
      ...adHocKeySequences.slice(targetKeySequenceIndex + 1),
    ].reduce<RegisteredKeyboardShortcutDefinitionSet<string>>((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

    this.registeredDefinitionSets$$.next({
      ...otherSets,
      [AD_HOC_DEFINITION_SET_ID]: updatedAdHocSet,
    });
  }

  /**
   * Registers a set of keyboard shortcuts definitions
   * @param setId ID for the set (for unregistering purposes)
   * @param definitionSet The shortcuts definitions
   * @param callbackFactory A factory function for generating callbacks for each shortcut
   */
  registerDefinitionSet<T extends string>(
    setId: string,
    definitionSet: KeyboardShortcutDefinitionSet<T>,
    callbackFactory?: (keyboardShortcutId: T) => KeySequence['callback'],
  ) {
    const setToRegister: RegisteredKeyboardShortcutDefinitionSet<T> = {};

    entries({ ...definitionSet }).forEach(([id, keySequence]) => {
      assertNonNullable(keySequence, 'keySequence');
      const { sequence, name, description } = keySequence;

      const namespace = keySequence.namespace ?? KeyboardShortcutsNamespace.Global;

      const callback =
        keySequence.callback ??
        callbackFactory?.(id) ??
        (() => {
          if (!IS_PROD_BUILD) {
            console.warn(`Triggered keyboard shortcut with empty callback: [${id}]`);
          }
        });

      setToRegister[id] = { ...keySequence, id, namespace, callback } satisfies NamedKeySequence;

      this.register({
        id,
        sequence,
        name,
        description,
        namespace,
        callback,
      });
    });

    const existingSets = this.getAllRegisteredSets();

    this.registeredDefinitionSets$$.next({ ...existingSets, [setId]: { ...existingSets['setId'], ...setToRegister } });
  }

  unregisterDefinitionSet(setId: string) {
    const { [setId]: setToUnregister, ...updatedSets } = this.getAllRegisteredSets();

    Object.values(setToUnregister ?? {})
      .filter(isNotNullish)
      .forEach(({ id, namespace }) => {
        this.unregister(id, namespace);
      });

    this.registeredDefinitionSets$$.next(updatedSets);
  }

  selectRegisteredKeySequences(setId?: string): Observable<RegisteredKeyboardShortcutDefinitionSet<string>> {
    return this.registeredDefinitionSets$.pipe(
      map((sets) => {
        if (setId) {
          return sets[setId] ?? {};
        }

        return Object.values(sets).reduce<RegisteredKeyboardShortcutDefinitionSet<string>>(
          (acc, set) => ({ ...acc, ...set }),
          {},
        );
      }),
    );
  }

  selectRegisteredKeySequence(
    id: string,
  ): Observable<UnwrapRecordValue<RegisteredKeyboardShortcutDefinitionSet<string>> | undefined> {
    return this.selectRegisteredKeySequences().pipe(map((allRegistered) => allRegistered[id]));
  }

  getAllRegisteredSets() {
    return this.registeredDefinitionSets$$.getValue();
  }

  getRegisteredKeySequence(id: string): UnwrapRecordValue<RegisteredKeyboardShortcutDefinitionSet<string>> | undefined {
    const registeredSets = this.getAllRegisteredSets();
    const allRegistered = Object.values(registeredSets).reduce((acc, set) => ({ ...acc, ...set }), {});
    return allRegistered[id];
  }

  getLabels(sequence: string): KeySequenceLabel[] {
    return sequence.split('+').map((key) => this.getPlatformSpecificButton(key));
  }

  getRawLabel(labels: KeySequenceLabel[]) {
    return labels.map((l) => l.base).join(' + ');
  }

  getHtmlLabelsForSequence(keySequence: KeySequence['sequence']): string[] {
    return (
      ensureArray(keySequence)
        .map((sequence) => this.getLabels(sequence))
        // remove duplicates
        .reduce<KeySequenceLabel[][]>((acc, curr) => {
          const alreadyExists = acc.find((label) => isEqual(label, curr));
          if (alreadyExists) {
            return acc;
          }
          return [...acc, curr];
        }, [])
        .map((labels) =>
          labels
            .map(
              (label) =>
                `<kbd class="shortcut-key">
                  <span class="${label.base === 'cmd' ? 'cmd-key' : ''}">${(
                    label.decorator ?? label.base
                  ).toUpperCase()}</span>
                </kbd>`,
            )
            .join(''),
        )
    );
  }

  private register(keySequence: KeySequence) {
    const { id, callback, name, description } = keySequence;
    const namespace = keySequence.namespace ?? KeyboardShortcutsNamespace.Global;

    ensureArray(keySequence.sequence).forEach((sequence) =>
      this.registerSingle({
        id,
        sequence,
        callback,
        namespace,
        name,
        description,
      }),
    );
  }

  private registerSingle(keySequence: KeySequenceStored) {
    const { id, sequence, callback, namespace, name, description } = keySequence;
    const stacks = this.keySequenceStacks$$.value;
    const stack = stacks[sequence];
    const alreadyRegistered = !!stack?.find((ks) => areKeySequencesEqual(ks, keySequence));

    if (alreadyRegistered) {
      return;
    }

    const storedSequence = { id, sequence, callback, name, description, namespace };

    this.keySequenceStacks$$.next({ ...stacks, [sequence]: [storedSequence, ...(stack ?? [])] });
  }

  private unregister(id: string, namespace?: KeyboardShortcutsNamespace) {
    const stacks = this.keySequenceStacks$$.value;
    const storedKeySequences = Object.values(stacks)
      .flat()
      .filter((seq) => seq.id === id && (namespace ? seq.namespace === namespace : true));

    if (!storedKeySequences.length) {
      this.errorHandlerService.log(`Trying to unregister a keyboard shortcut that wasn't registered: ${id}`, {
        context: { id, registeredStacks: stacks },
      });
      return;
    }

    storedKeySequences.forEach((keySequenceStored) => {
      const stackIndex = keySequenceStored.sequence;
      const stack = stacks[stackIndex];
      assertNonNullable(stack, 'stack');

      this.keySequenceStacks$$.next({
        ...this.keySequenceStacks$$.value,
        [stackIndex]: stack.filter((ks) => !areKeySequencesEqual(ks, keySequenceStored)),
      });
    });
  }

  private getPlatformSpecificButton(sequenceChunk: string): KeySequenceLabel {
    const foundInMap = this.keysMap?.[sequenceChunk];

    if (foundInMap) {
      return { ...foundInMap };
    }

    return {
      base: sequenceChunk,
    };
  }

  private overrideStopCallback() {
    const stopCallbackOverride: MousetrapStatic['stopCallback'] = (
      e: ExtendedKeyboardEvent,
      element: Element,
      combo: string,
    ) => {
      if (element.classList.contains('mousetrap')) {
        return false;
      }

      const shouldSuppressByClass = !!element.closest(`.${SUPPRESS_REGISTERED_KEYBOARD_SHORTCUTS_CLASS}`);

      if (shouldSuppressByClass) {
        return true;
      }

      if (isEditableElement(element)) {
        return !isShortcutComboInputSafe(combo);
      }

      return false;
    };

    this.mousetrapInstance.stopCallback = stopCallbackOverride;
  }

  /**
   * Updates the bound keyboard shortcut actions when a stack of key sequences changes
   */
  private updateBoundSequenceOnStackChange() {
    const updatedStacks$ = this.keySequenceStacks$.pipe(
      pairwise(),
      map(([stacksBefore, stacksNow]) =>
        entries(stacksNow).filter(([sequence, keySequences]) => {
          const firstKeySequenceBefore = stacksBefore[sequence]?.[0];
          const firstKeySequenceNow = keySequences[0];

          if (!firstKeySequenceBefore || !firstKeySequenceNow) {
            return firstKeySequenceBefore !== firstKeySequenceNow;
          }

          return !areKeySequencesEqual(firstKeySequenceBefore, firstKeySequenceNow);
        }),
      ),
    );

    return updatedStacks$.pipe(
      filter((updatedStacks) => !!updatedStacks.length),
      tap((updatedStacks) =>
        updatedStacks.forEach(([sequence, keySequences]) => {
          this.mousetrapInstance.unbind(sequence);

          if (!keySequences.length) {
            return;
          }

          const firstKeySequence = keySequences[0];
          assertNonNullable(firstKeySequence, 'firstKeySequence');

          this.mousetrapInstance.bind(sequence, (e, combo) => {
            firstKeySequence.callback(e, combo);
            return true;
          });
        }),
      ),
    );
  }
}
