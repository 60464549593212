import { Pipe, PipeTransform } from '@angular/core';
import { Datestring } from './date-time.model';
import { DateTimeService } from './date-time.service';

@Pipe({ name: 'formatDateRelativeToNow' })
export class FormatDateRelativeToNowPipe implements PipeTransform {
  constructor(private readonly dateTimeService: DateTimeService) {}

  transform(date: Datestring | undefined, addSuffix = true): string {
    if (!date) {
      return '';
    }

    return this.dateTimeService.formatDateRelativeToNow(date, addSuffix);
  }
}
