import { Type } from '@angular/core';
import { FetchCategoryTopicAreaEffectService } from './fetch-category-topic-area-effect.service';
import { HandleEmailVerifiedService } from './handle-email-verified.service';
import { PosthogUserTraitsService } from './posthog-user-traits.service';

export const APP_EFFECTS: Array<Type<unknown>> = [
  PosthogUserTraitsService,
  FetchCategoryTopicAreaEffectService,
  HandleEmailVerifiedService,
];
