<div [mtxPopoverTriggerOn]="triggerOn" [mtxPopoverTriggerFor]="popover">
  <ng-content></ng-content>
</div>

<mtx-popover #popover="mtxPopover" [position]="position" [class]="state.popoverClass">
  @if (state.keySequence) {
    <div class="flex items-center">
      @if (title) {
        <span class="mr-2">{{ title }}</span>
      }
      <y42-keyboard-shortcut-sequence [sequence]="state.keySequence"></y42-keyboard-shortcut-sequence>
    </div>
    @if (description) {
      <div class="text-typography-secondary mt-2">{{ description }}</div>
    }

    @if (extraContent) {
      <ng-container [ngTemplateOutlet]="extraContent"></ng-container>
    }
  }
</mtx-popover>
