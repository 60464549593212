import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { FeatureFlagService } from '@examdojo/core/feature-flag';
import { ExamDojoFeatureFlag, ExamdojoFeatureFlags } from '@examdojo/models/feature-flag';
import { NgClass } from '@angular/common';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs';
import { Platform } from '@ionic/angular/standalone';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SidenavComponent } from './sidenav/sidenav.component';

@Component({
  selector: 'dojo-shell',
  standalone: true,
  imports: [RouterOutlet, MatSidenavModule, SidenavComponent, NgClass],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'h-full' },
})
export class ShellComponent {
  constructor(
    private readonly featureFlagService: FeatureFlagService<ExamdojoFeatureFlags>,
    private readonly platform: Platform,
  ) {
    this.reactToResize().pipe(takeUntilDestroyed()).subscribe();
  }

  readonly isCompact = signal<boolean>(false);

  readonly state = connectState({
    showSideNav: this.featureFlagService.select(ExamDojoFeatureFlag.Sidenav),
  });

  private reactToResize() {
    return this.platform.resize.pipe(
      startWith(null),
      map(() => this.platform.width() < 1100),
      distinctUntilChanged(),
      tap((isCompact) => this.isCompact.set(isCompact)),
    );
  }
}
