import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, finalize, map, Observable, tap } from 'rxjs';
import { mapToVoid } from '@examdojo/core/rxjs';
import { isNotNullish } from '@examdojo/core/util/nullish';
import { DateTimeService } from '@examdojo/core/date-time';
import { BillingHttpService } from './billing-http.service';
import { BillingHttpModel } from './billing.model';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class BillingService {
  constructor(
    private readonly billingHttpService: BillingHttpService,
    private readonly dateTimeService: DateTimeService,
  ) {}

  readonly activeSubscription$$ = new BehaviorSubject<BillingHttpModel | null>(null);
  readonly activeSubscription$ = this.activeSubscription$$.asObservable();

  readonly checkoutSessionLoading$$ = new BehaviorSubject(false);
  readonly checkoutSessionLoading$ = this.checkoutSessionLoading$$.asObservable();

  readonly customerPortalLoading$$ = new BehaviorSubject(false);
  readonly customerPortalLoading$ = this.customerPortalLoading$$.asObservable();

  readonly userHasProPlan$ = this.activeSubscription$.pipe(
    map(
      (subscription) =>
        /*
            Per db design, there is no identification of free plan in a table. If any record exists in the user_subscriptions table, it means the user has a pro plan.  
        */
        isNotNullish(subscription) &&
        isNotNullish(subscription.start_date) &&
        (isNotNullish(subscription.end_date) ? this.dateTimeService.isAfter(subscription.end_date, new Date()) : true),
    ),
    map((hasProPlan) => !!hasProPlan),
  );

  fetchActiveSubscription(userId: string): Observable<void> {
    return this.billingHttpService.getActiveSubscription(userId).pipe(
      tap((subscription) => {
        this.activeSubscription$$.next(subscription);
      }),
      mapToVoid(),
    );
  }

  fetchCustomerPortalUrl(): Observable<string> {
    this.customerPortalLoading$$.next(true);
    return this.billingHttpService.getCustomerPortalSession().pipe(
      map((sessionUrl) => sessionUrl.result.link),
      finalize(() => this.customerPortalLoading$$.next(false)),
    );
  }

  fetchCheckoutSessionUrl(): Observable<string> {
    this.checkoutSessionLoading$$.next(true);
    return this.billingHttpService.getCheckoutSession().pipe(
      map((sessionUrl) => sessionUrl.result.link),
      finalize(() => this.checkoutSessionLoading$$.next(false)),
    );
  }
}
