import { Route } from '@angular/router';
import { redirectToGuard } from '@examdojo/auth';
import { billingInfoResolver } from '@examdojo/billing';
import { categoriesResolver } from '@examdojo/category';
import { topicsTreeResolver } from '@examdojo/category/v2';
import { NotFoundComponent } from '@examdojo/core/not-found';
import { AppOnboardingDialogService } from './app-onboarding/app-onboarding-dialog.service';
import { appOnboardingGuard } from './app-onboarding/app-onboarding.guard';
import { RootUrlParts } from './app.model';
import { AuthGuard } from './auth/auth.guard';
import { authRoutes } from './auth/auth.routes';
import { LEARN_HUB_ROUTES } from './features/learn-hub/learn-hub.routes';
import { syllabusesResolver } from './features/learn-hub/syllabus.resolver';
import { ShellComponent } from './shell/shell.component';
import { userOnboardingGuard } from './user-onboarding/user-onboarding.guard';
import { userOnboardingRoutes } from './user-onboarding/user-onboarding.routes';
import { userSettingsResolver } from './user/user-settings-resolver';
import { hasRoleGuard, isOnWaitlistGuard } from './waitlist/waitlist.guard';

export const appRoutes: Route[] = [
  ...authRoutes,
  ...userOnboardingRoutes,
  {
    path: 'waitlist',
    canActivate: [AuthGuard, isOnWaitlistGuard],
    loadComponent: () => import('./waitlist/waitlist.component').then((m) => m.WaitlistComponent),
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthGuard, hasRoleGuard, redirectToGuard, userOnboardingGuard, appOnboardingGuard],
    providers: [AppOnboardingDialogService],
    resolve: {
      categories: categoriesResolver,
      topicsTree: topicsTreeResolver,
      syllabuses: syllabusesResolver,
      billing: billingInfoResolver,
      userSettings: userSettingsResolver,
    },
    children: [
      {
        path: '',
        redirectTo: RootUrlParts.TopicPractice,
        pathMatch: 'full',
      },
      {
        path: RootUrlParts.TopicPracticeV2,
        loadComponent: () =>
          import('./features/topic-practice-v2/topic-practice-v2.component').then((m) => m.TopicPracticeV2Component),
      },
      {
        path: RootUrlParts.TopicPractice,
        loadChildren: () =>
          import('./features/topic-practice/topic-practice.routes').then((m) => m.TOPIC_PRACTICE_ROUTES),
      },
      {
        path: RootUrlParts.Settings,
        loadChildren: () => import('./settings/settings.routes').then((m) => m.PROFILE_ROUTES),
      },
      {
        path: RootUrlParts.Home,
        loadComponent: () => import('./features/home/home.component').then((m) => m.HomeComponent),
      },
      {
        path: RootUrlParts.Assessments,
        loadComponent: () => import('./features/assessments/assessments.component').then((m) => m.AssessmentsComponent),
      },
      {
        path: RootUrlParts.Leaderboards,
        loadComponent: () =>
          import('./features/leaderboards/leaderboards.component').then((m) => m.LeaderboardsComponent),
      },
      ...LEARN_HUB_ROUTES,
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
