import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Router } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { IconComponent, IconSizeType } from '@examdojo/core/icon';
import { combineLatest, map } from 'rxjs';
import { RootUrlParts } from '../../app.model';
import { UserQuery } from '../../user/user.query';

@Component({
  selector: 'dojo-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: { class: 'flex' },
  imports: [IconComponent],
})
export class ProfileAvatarComponent {
  constructor(
    private readonly userQuery: UserQuery,
    private readonly router: Router,
  ) {}

  readonly size = input<IconSizeType>(16);

  readonly state = connectState({
    userUrl: combineLatest([this.userQuery.profilePic$, this.userQuery.oAuthProfilePic$]).pipe(
      map(
        ([profilePic, oAuthProfilePic]) => (profilePic || oAuthProfilePic) as `${string}.${'png' | 'jpg'}` | undefined,
      ),
    ),
  });

  navigateToProfile() {
    this.router.navigate([RootUrlParts.Settings]);
  }
}
